import React, { useState, useEffect } from 'react';
import { fetchBookings, fetchProfessionals, createPayment } from '../api';
import { ClipLoader } from 'react-spinners';

function CommissionModal({ show, onClose }) {
  const [commissions, setCommissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [professionals, setProfessionals] = useState([]);
  const [expirationDate, setExpirationDate] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setDefaultExpirationDate();
      initializeData();
    }
  }, [show]);

  const setDefaultExpirationDate = () => {
    const now = new Date();
    const friday = new Date(now);
    friday.setDate(now.getDate() + (5 - now.getDay()));
    setExpirationDate(friday.toISOString().split('T')[0]);
  };

  const initializeData = async () => {
    try {
      setLoading(true);
      // First load professionals
      console.log("fetching professionals");
      const data = await fetchProfessionals();
      const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
        name: name,
        ...details
      }));
      setProfessionals(professionalsList);

      // Then load commissions with the professionals data available
      await loadCommissions(professionalsList);
    } catch (error) {
      console.error('Error initializing data:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadCommissions = async (profList) => {
    try {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);

      const bookings = await fetchBookings({  
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        comissionStatus: 'unpaid'
      });

      console.log("bookings", bookings);

      const groupedCommissions = groupBookingsByProfessional(bookings, profList);
      console.log("groupedCommissions", groupedCommissions);
      setCommissions(groupedCommissions);
    } catch (error) {
      console.error('Error loading commissions:', error);
    }
  };

  const getProfessionalName = (professionalId, profList) => {
    const professional = profList.find(p => p.id === professionalId);
    return professional ? professional.name : professionalId;
  };

  const groupBookingsByProfessional = (bookings, profList) => {
    const grouped = {};
    bookings.forEach(booking => {
      const profId = booking.profissional;
      if (!grouped[profId]) {
        grouped[profId] = {
          name: getProfessionalName(profId, profList),
          total: 0,
          bookingIds: [] // Add bookingIds array
        };
      }
      grouped[profId].total += booking.comission || 0;
      grouped[profId].bookingIds.push(booking.atendimento_id); // Add booking id to the array
    });
    return grouped;
  };

  const handleTotalChange = (profId, value) => {
    setCommissions(prev => ({
      ...prev,
      [profId]: {
        ...prev[profId],
        total: parseFloat(value) || 0
      }
    }));
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      
      // Create a payment for each professional
      const paymentPromises = Object.entries(commissions).map(([profId, data]) => {
        if (data.total <= 0) return null; // Skip if total is 0 or negative
        console.log(data);

        return createPayment({
          description: `Comissão - ${data.name}`,
          payment_type: 'comission',
          amount: data.total,
          bookingIds: data.bookingIds,
          expiration_date: `${expirationDate}T12:59:59Z`,
          professional_id: profId
        });
      }).filter(Boolean); // Remove null promises

      await Promise.all(paymentPromises);
      onClose(); // This will trigger the refresh in Financeiro.js
    } catch (error) {
      console.error('Error creating commission payments:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content dark" onClick={e => e.stopPropagation()}>
        <h2>Lançar Comissões</h2>
        
        <div className="date-selection">
          <label>Data de Vencimento:</label>
          <input
            type="date"
            value={expirationDate}
            onChange={(e) => setExpirationDate(e.target.value)}
            className="date-input"
          />
        </div>
        
        {loading ? (
          <div className="loading-container">
            <ClipLoader size={50} color={"#4a90e2"} />
          </div>
        ) : (
          <div className="commission-list">
            {Object.entries(commissions).map(([profId, data]) => (
              <div key={profId} className="commission-item">
                <div className="professional-name">{data.name}</div>
                <div className="commission-input">
                  <label>Valor Total:</label>
                  <input
                    type="number"
                    step="0.01"
                    value={data.total}
                    onChange={(e) => handleTotalChange(profId, e.target.value)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="modal-actions">
          <button 
            className="action-button" 
            onClick={onClose}
            disabled={submitting}
          >
            Cancelar
          </button>
          <button 
            className="action-button success"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? <ClipLoader size={20} color={"#ffffff"} /> : 'Confirmar'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommissionModal; 