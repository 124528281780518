import React, { useState, useEffect, useRef } from 'react';
import './ProductSearchSelect.css';

function ProductSearchSelect({ onSelect, placeholder = "Buscar produto..." }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [stockItems, setStockItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        // Load cached stock items
        const cachedData = localStorage.getItem('stockItemsCache');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const processedItems = parsedData.map(item => ({
                ...item,
                searchableText: item.description?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || ''
            }));
            setStockItems(processedItems);
        }

        // Click outside listener
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                inputRef.current && !inputRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const normalizedSearch = searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const filtered = stockItems.filter(item => 
                item.searchableText.includes(normalizedSearch) ||
                item.product_id?.toLowerCase().includes(normalizedSearch)
            );
            setFilteredItems(filtered);
            setShowDropdown(true);
        } else {
            setFilteredItems([]);
            setShowDropdown(false);
        }
    }, [searchTerm, stockItems]);

    const handleSelect = (item) => {
        onSelect(item);
        setSearchTerm('');
        setShowDropdown(false);
    };

    return (
        <div className="product-search-select">
            <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={placeholder}
                className="product-search-input"
                onFocus={() => searchTerm && setShowDropdown(true)}
            />
            {showDropdown && filteredItems.length > 0 && (
                <div ref={dropdownRef} className="product-search-dropdown">
                    {filteredItems.map((item, index) => (
                        <div
                            key={index}
                            className="product-search-item"
                            onClick={() => handleSelect(item)}
                        >
                            <div className="product-search-item-main">
                                <span className="product-search-item-name">{item.description}</span>
                                <span className="product-search-item-code">{item.product_id}</span>
                            </div>
                            <div className="product-search-item-details">
                                <span>Estoque: {item.qty}</span>
                                <span>R$ {item.price}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ProductSearchSelect; 