import React, { useState, useEffect } from 'react';
import './Estoque.css';
import { FaPlus, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
import { 
    fetchStock, 
    getStockMovements, 
    fetchStockRequisitions, 
    createStockRequisition,
    createInvoice,
    fetchInvoices,
    updateStockRequisition
} from '../api';
import StockModal from './StockModal';
import RequisitionModal from './RequisitionModal';
import InvoiceModal from './InvoiceModal';
import InvoiceInfoModal from './InvoiceInfoModal';
import ProductModal from './ProductModal';
import SupplierModal from './SupplierModal';
import SupplierSearchSelect from '../SupplierSearchSelect/SupplierSearchSelect';

function Estoque() {
    const [activeTab, setActiveTab] = useState('stock');
    const [showModal, setShowModal] = useState(false);
    const [showRequisitionModal, setShowRequisitionModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showInvoiceInfoModal, setShowInvoiceInfoModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showProductModal, setShowProductModal] = useState(false);
    const [selectedRequisition, setSelectedRequisition] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [stockItems, setStockItems] = useState([]);
    const [stockMovements, setStockMovements] = useState([]);
    const [requisitions, setRequisitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paginationToken, setPaginationToken] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [movementsLoading, setMovementsLoading] = useState(false);
    const [movementsError, setMovementsError] = useState(null);
    const [movementsPaginationToken, setMovementsPaginationToken] = useState(null);
    const [hasMoreMovements, setHasMoreMovements] = useState(true);
    const [requisitionsLoading, setRequisitionsLoading] = useState(false);
    const [requisitionsError, setRequisitionsError] = useState(null);
    const [requisitionsPaginationToken, setRequisitionsPaginationToken] = useState(null);
    const [hasMoreRequisitions, setHasMoreRequisitions] = useState(true);
    const [requisitionStatusFilter, setRequisitionStatusFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [invoicesLoading, setInvoicesLoading] = useState(false);
    const [invoicesError, setInvoicesError] = useState(null);
    const [invoicesPaginationToken, setInvoicesPaginationToken] = useState(null);
    const [hasMoreInvoices, setHasMoreInvoices] = useState(true);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [invoiceStatus, setInvoiceStatus] = useState('sent');
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);


    const processStockItems = (items) => {
        return items.map(item => ({
            ...item,
            searchableText: item.description?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || ''
        }));
    };

    const loadStockData = async (token = null, useCache = true) => {
        try {
            if (!token && useCache) {
                const cachedData = localStorage.getItem('stockItemsCache');
                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    setStockItems(processStockItems(parsedData));
                    setHasMore(false);
                    return;
                }
            }

            setLoading(true);
            setError(null);

            if (!token) {
                let allItems = [];
                let nextToken = null;
                
                do {
                    const data = await fetchStock(nextToken);
                    allItems = [...allItems, ...data.items];
                    nextToken = data.paginationToken;
                } while (nextToken);

                const processedItems = processStockItems(allItems);
                setStockItems(processedItems);
                setHasMore(false);
                
                localStorage.setItem('stockItemsCache', JSON.stringify(allItems));
            } else {
                const data = await fetchStock(token);
                const processedItems = processStockItems(data.items);
                setStockItems(prev => [...prev, ...processedItems]);
                setHasMore(!!data.paginationToken);
                setPaginationToken(data.paginationToken);
            }
        } catch (err) {
            setError('Failed to load stock data');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const loadStockMovements = async (token = null) => {
        try {
            setMovementsLoading(true);
            setMovementsError(null);
            const data = await getStockMovements(token);
            
            if (token) {
                setStockMovements(prev => [...prev, ...data.items]);
            } else {
                setStockMovements(data.items);
            }
            
            setHasMoreMovements(!!data.paginationToken);
            setMovementsPaginationToken(data.paginationToken);
        } catch (err) {
            setMovementsError('Failed to load stock movements');
            console.error(err);
        } finally {
            setMovementsLoading(false);
        }
    };

    const loadRequisitions = async (token = null, useCache = true) => {
        try {
            console.log("loading requisitions", token, useCache);
            // Check cache first if useCache is true
            if (!token && useCache) {
                const cachedData = localStorage.getItem('stockRequisitionsCache');
                if (cachedData) {
                    console.log("using cached requisitions", cachedData);
                    const parsedData = JSON.parse(cachedData);
                    setRequisitions(parsedData);
                    setHasMoreRequisitions(false);
                    return;
                }
            }

            if (!useCache) {
                localStorage.removeItem('stockRequisitionsCache');
            }

            setRequisitionsLoading(true);
            setRequisitionsError(null);
            
            // If no token, we're starting fresh - let's get ALL pages

            if (!token) {
                let allRequisitions = [];
                let nextToken = null;
                
                do {
                    const data = await fetchStockRequisitions(nextToken);
                    allRequisitions = [...allRequisitions, ...data.items];
                    nextToken = data.paginationToken;
                } while (nextToken);

                setRequisitions(allRequisitions);
                setHasMoreRequisitions(false);
                
                // Cache the complete data
                localStorage.setItem('stockRequisitionsCache', JSON.stringify(allRequisitions));
            } else {
                // This branch is kept for compatibility but shouldn't be used anymore
                const data = await fetchStockRequisitions(token);
                setRequisitions(prev => [...prev, ...data.items]);
                setHasMoreRequisitions(!!data.paginationToken);
                setRequisitionsPaginationToken(data.paginationToken);
            }
        } catch (err) {
            setRequisitionsError('Failed to load requisitions');
            console.error(err);
        } finally {
            setRequisitionsLoading(false);
        }
    };

    const loadInvoices = async (token = null) => {
        try {
            setInvoicesLoading(true);
            setInvoicesError(null);
            console.log("invoiceStatus", invoiceStatus);
            const filters = {
                status: invoiceStatus
            };
            
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;

            console.log("filters", filters);
            
            const data = await fetchInvoices(token, filters);
            console.log("data", data);
            

            if (token) {
                setInvoices(prev => [...prev, ...data.items]);
            } else {
                setInvoices(data.items);
            }
            
            setHasMoreInvoices(!!data.paginationToken);
            setInvoicesPaginationToken(data.paginationToken);
        } catch (err) {
            setInvoicesError('Failed to load invoices');
            console.error(err);
        } finally {
            setInvoicesLoading(false);
        }
    };

    useEffect(() => {
        loadStockData();
        loadStockMovements();
        loadRequisitions();
        loadInvoices();
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseRequisitionModal = () => {
        setShowRequisitionModal(false);
        setSelectedRequisition(null);
    };

    const handleShowRequisitionModal = (requisition = null) => {
        setSelectedRequisition(requisition);
        setShowRequisitionModal(true);
    };

    const handleStockChange = async (stockChangeData) => {
        try {
            // Add the new movement to the list
            const newMovement = {
                ...stockChangeData,
                change_id: Date.now().toString(),
                timestamp: new Date().toISOString()
            };
            setStockMovements(prev => [newMovement, ...prev]);

            // Refresh both stock data and movements
            await Promise.all([
                loadStockData(),
                loadStockMovements()
            ]);
            handleCloseModal();
        } catch (err) {
            setError('Failed to process stock change');
            console.error(err);
        }
    };

    const handleCreateRequisition = async (requisitionData) => {
        try {
            await createStockRequisition(requisitionData);
            // Force reload without using cache after creating new requisition
            await loadRequisitions(null, false);
            handleCloseRequisitionModal();
        } catch (err) {
            setError('Failed to create requisition');
            console.error(err);
        }
    };

    const handleAttendRequisition = async (requisitionId, requisitionTimestamp) => {
        try {
            // Update the requisition first. This is not needed, because the backend function for this does both
            // await updateStockRequisition(requisitionId, requisitionTimestamp, { status: 'Fechado' });
            // Refresh all relevant data states
            await Promise.all([
                loadRequisitions(null, false),  // Refresh requisitions
                loadStockData(null, false),     // Refresh stock
                loadStockMovements()            // Refresh movements
            ]);
        } catch (err) {
            console.error(err);
        }
    };

    const handleCloseInvoiceModal = () => {
        setShowInvoiceModal(false);
    };

    const handleShowInvoiceModal = () => {
        setShowInvoiceModal(true);
    };

    const handleCreateInvoice = async (invoiceData) => {
        try {
            await createInvoice(invoiceData);
            await Promise.all([
                loadInvoices(),  // Refresh invoices
                loadStockData(null, false),  // Refresh stock
                loadStockMovements()  // Refresh movements
            ]);
            handleCloseInvoiceModal();
        } catch (err) {
            setError('Failed to create invoice');
            console.error(err);
        }
    };

    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };

    const handleShowProductModal = () => {
        setShowProductModal(true);
    };

    const handleCreateProduct = async (productData) => {
        try {
            // Force reload without cache after creating a new product
            await loadStockData(null, false);
        } catch (err) {
            setError('Failed to refresh stock data');
            console.error(err);
        }
    };

    const handleCloseInvoiceInfoModal = () => {
        setShowInvoiceInfoModal(false);
        setSelectedInvoice(null);
    };

    const handleShowInvoiceInfoModal = (invoice) => {
        setSelectedInvoice(invoice);
        setShowInvoiceInfoModal(true);
    };

    const handleDeleteRequisition = async (requisitionId, timestamp) => {
        console.log("requisitionId", requisitionId);
        console.log("timestamp", timestamp);
        if (window.confirm('Quer mesmo deletar a requisição?')) {
            try {
                await updateStockRequisition(requisitionId, timestamp,{} , true);

                // Force reload without cache after deleting
                await loadRequisitions(null, false);
            } catch (err) {
                setError('Failed to delete requisition');
                console.error(err);
            }
        }
    };

    const filteredRequisitions = requisitions.filter(req => {
        if (requisitionStatusFilter === 'all') return true;
        return req.status === requisitionStatusFilter;
    });

    // Filter items based on search term
    const filteredStockItems = stockItems.filter(item => 
        !searchTerm || item.searchableText.includes(
            searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        )
    ).sort((a, b) => {
        // Sort by criticality: red (most critical) first, then yellow, then normal
        const getCriticality = (item) => {
            if (item.qty < item.min) return 2;
            if (item.qty <= item.ideal) return 1;
            return 0;
        };
        return getCriticality(b) - getCriticality(a);
    });

    const getRowStyle = (item) => {
        if (item.qty < item.min) {
            return { backgroundColor: 'rgba(255, 0, 0, 0.1)' };
        }
        if (item.qty <= item.ideal) {
            return { backgroundColor: 'rgba(255, 255, 0, 0.1)' };
        }
        return {};
    };

    const handleCloseSupplierModal = () => {
        setShowSupplierModal(false);
    };

    const handleShowSupplierModal = () => {
        setShowSupplierModal(true);
    };

    const handleSupplierSelect = (supplier) => {
        setSelectedSupplier(supplier);
    };

    const getStockWarning = (item) => {
        if (item.qty < item.min) {
            return (
                <div className="warning-container">
                    <FaExclamationTriangle style={{ color: 'red' }} />
                    <span className="custom-tooltip">Produto em baixa quantidade</span>
                </div>
            );
        }
        if (item.qty <= item.ideal) {
            return (
                <div className="warning-container">
                    <FaExclamationTriangle style={{ color: '#ffd700' }} />
                    <span className="custom-tooltip">Produto próximo da quantidade mínima</span>
                </div>
            );
        }
        return null;
    };

    const styles = `
        .warning-container {
            position: relative;
            display: inline-block;
            margin-right: 5px;
        }

        .custom-tooltip {
            visibility: hidden;
            background-color: #333;
            color: white;
            text-align: center;
            padding: 5px 10px;
            border-radius: 4px;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 0;
            transform: translateX(10px);
            white-space: nowrap;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.1s;
        }

        .warning-container:hover .custom-tooltip {
            visibility: visible;
            opacity: 1;
        }
    `;

    return (
        <div className="estoque-container">
            <style>{styles}</style>
            <InvoiceInfoModal
                show={showInvoiceInfoModal}
                onClose={handleCloseInvoiceInfoModal}
                invoice={selectedInvoice}
            />
            <div className="estoque-tabs">
                <button 
                    className={`tab-button ${activeTab === 'stock' ? 'active' : ''}`}
                    onClick={() => setActiveTab('stock')}
                >
                    Estoque Atual
                </button>
                <button 
                    className={`tab-button ${activeTab === 'movements' ? 'active' : ''}`}
                    onClick={() => setActiveTab('movements')}
                >
                    Movimentações
                </button>
                <button 
                    className={`tab-button ${activeTab === 'requisitions' ? 'active' : ''}`}
                    onClick={() => setActiveTab('requisitions')}
                >
                    Requisições
                </button>
                <button 
                    className={`tab-button ${activeTab === 'invoices' ? 'active' : ''}`}
                    onClick={() => setActiveTab('invoices')}
                >
                    Entradas
                </button>
                <button 
                    className={`tab-button ${activeTab === 'suppliers' ? 'active' : ''}`}
                    onClick={() => setActiveTab('suppliers')}
                >
                    Fornecedores
                </button>
            </div>

            {activeTab === 'stock' && (
                <>
                    <div className="table-actions">
                        <button className="success" onClick={handleShowProductModal}>
                            <FaPlus /> Adicionar Produto
                        </button>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Buscar produtos..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="search-input"
                            />
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantidade</th>
                                <th>Código do item</th>
                                <th>Ideal</th>
                                <th>Minimo</th>
                                <th>Preço</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStockItems.map((item, index) => (
                                <tr key={index} style={getRowStyle(item)}>
                                    <td>
                                        {getStockWarning(item)}
                                        {item.description}
                                    </td>
                                    <td>{item.qty}</td>
                                    <td>{item.product_id}</td>
                                    <td>{item.ideal}</td>
                                    <td>{item.min}</td>
                                    <td>R$ {item.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {loading && <div className="loading">Loading...</div>}
                </>
            )}

            {activeTab === 'movements' && (
                <>
                    {movementsError && <div className="error-message">{movementsError}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Item</th>
                                <th>Tipo</th>
                                <th>Quantidade</th>
                                <th>NF</th>
                                <th>Requisitante</th>
                                <th>Observações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockMovements.map((movement) => (
                                <tr key={movement.change_id}>
                                    <td>{new Date(movement.timestamp).toLocaleDateString()}</td>
                                    <td>{movement.product_id}</td>
                                    <td>{movement.type === 'in' ? 'Entrada' : 'Saída'}</td>
                                    <td>{Math.abs(movement.quantity_change)}</td>
                                    <td>{movement.nf_number}</td>
                                    <td>{movement.requester}</td>
                                    <td>{movement.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {movementsLoading && <div className="loading">Loading...</div>}
                </>
            )}

            {activeTab === 'requisitions' && (
                <>
                    <div className="table-actions">
                        <button className="success" onClick={() => handleShowRequisitionModal()}>
                            <FaPlus /> Criar Requisição de saída
                        </button>
                        <select 
                            value={requisitionStatusFilter}
                            onChange={(e) => setRequisitionStatusFilter(e.target.value)}
                            className="status-filter"
                        >
                            <option value="all">Todos</option>
                            <option value="Aberta">Aberta</option>
                            <option value="Fechado">Fechado</option>
                        </select>
                    </div>
                    {requisitionsError && <div className="error-message">{requisitionsError}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Requisitante</th>
                                <th>Status</th>
                                <th>Observações</th>
                                <th>Deletar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRequisitions.map((req) => (
                                <tr key={req.requisition_id}>
                                    <td onClick={() => handleShowRequisitionModal(req)}>{new Date(req.timestamp).toLocaleDateString()}</td>
                                    <td onClick={() => handleShowRequisitionModal(req)}>{req.requester}</td>
                                    <td onClick={() => handleShowRequisitionModal(req)}>{req.status}</td>
                                    <td onClick={() => handleShowRequisitionModal(req)}>{req.notes}</td>
                                    <td>
                                        <button 
                                            className="delete-button"
                                            onClick={(e) => {
                                                console.log("req", req);
                                                e.stopPropagation();
                                                handleDeleteRequisition(req.requisition_id, req.timestamp);
                                            }}
                                        >
                                            <FaTimes />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {requisitionsLoading && <div className="loading">Loading...</div>}
                </>
            )}

            {activeTab === 'invoices' && (
                <>
                    <div className="table-actions">
                        <button className="success" onClick={handleShowInvoiceModal}>
                            <FaPlus /> Adicionar nota fiscal
                        </button>
                        <div className="date-filters">
                            <div className="date-input-group">
                                <label>Data Inicial:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        setInvoices([]); // Clear current invoices
                                        setInvoicesPaginationToken(null);
                                    }}
                                />
                            </div>
                            <div className="date-input-group">
                                <label>Data Final:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                        setInvoices([]); // Clear current invoices
                                        setInvoicesPaginationToken(null);
                                    }}
                                />
                            </div>
                            <div className="date-input-group">
                                <label>Status:</label>
                                <select
                                    value={invoiceStatus}
                                    onChange={(e) => {
                                        setInvoiceStatus(e.target.value);
                                        setInvoices([]); // Clear current invoices
                                        setInvoicesPaginationToken(null);
                                    }}
                                    className="status-select"
                                >
                                    <option value="sent">Aberta</option>
                                    <option value="paid">Paga</option>
                                </select>
                            </div>

                            <button 
                                className="dark-button"
                                onClick={() => loadInvoices()}
                                disabled={invoicesLoading}
                            >
                                Filtrar
                            </button>
                        </div>
                    </div>
                    {invoicesError && <div className="error-message">{invoicesError}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Data Vencimento</th>
                                <th>Número NF</th>
                                <th>Fornecedor</th>
                                <th>Valor Total</th>
                                {/* <th>Status</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.length === 0 ? <tr><td colSpan="6">No invoices found</td></tr> : invoices.map((invoice) => (
                                <tr 
                                    key={invoice.invoice_id}
                                    onClick={() => handleShowInvoiceInfoModal(invoice)}
                                    className="clickable-row"
                                >
                                    <td>{new Date(invoice.created_at).toLocaleDateString()}</td>
                                    <td>{invoice.expiration_date ? new Date(invoice.expiration_date).toLocaleDateString() : '-'}</td>
                                    <td>{invoice.nf_number || '-'}</td>
                                    <td>{invoice.supplier || '-'}</td>
                                    <td>R$ {invoice.total_value ? invoice.total_value.toFixed(2) : '0.00'}</td>
                                    {/* <td>{invoice.status || '-'}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {invoicesLoading && <div className="loading">Loading...</div>}
                    {hasMoreInvoices && (
                        <button 
                            className="load-more"
                            onClick={() => loadInvoices(invoicesPaginationToken)}
                            disabled={invoicesLoading}
                        >
                            Carregar mais
                        </button>
                    )}
                </>
            )}

            {activeTab === 'suppliers' && (
                <>
                    <div className="table-actions">
                        <button className="success" onClick={handleShowSupplierModal}>
                            <FaPlus /> Adicionar Fornecedor
                        </button>
                        <div className="search-container">
                            <SupplierSearchSelect onSupplierSelect={handleSupplierSelect} />
                        </div>
                    </div>
                    {selectedSupplier && (
                        <div className="supplier-details">
                            <h3>Detalhes do Fornecedor</h3>
                            <div className="details-grid">
                                <div className="detail-item">
                                    <label>Nome:</label>
                                    <span>{selectedSupplier.name}</span>
                                </div>
                                <div className="detail-item">
                                    <label>CNPJ:</label>
                                    <span>{selectedSupplier.cnpj}</span>
                                </div>
                                <div className="detail-item">
                                    <label>Email:</label>
                                    <span>{selectedSupplier.email}</span>
                                </div>
                                <div className="detail-item">
                                    <label>Telefone:</label>
                                    <span>{selectedSupplier.phone}</span>
                                </div>
                                {selectedSupplier.address && (
                                    <div className="detail-item">
                                        <label>Endereço:</label>
                                        <span>{selectedSupplier.address}</span>
                                    </div>
                                )}
                                {selectedSupplier.contact_person && (
                                    <div className="detail-item">
                                        <label>Pessoa de Contato:</label>
                                        <span>{selectedSupplier.contact_person}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}

            <StockModal
                show={showModal}
                onClose={handleCloseModal}
                type={modalType}
                onSubmit={handleStockChange}
            />

            <RequisitionModal
                show={showRequisitionModal}
                onClose={handleCloseRequisitionModal}
                requisition={selectedRequisition}
                onSubmit={handleCreateRequisition}
                onAttendRequisition={handleAttendRequisition}
            />

            <InvoiceModal
                show={showInvoiceModal}
                onClose={handleCloseInvoiceModal}
                onSubmit={handleCreateInvoice}
            />

            <ProductModal
                show={showProductModal}
                onClose={handleCloseProductModal}
                onSubmit={handleCreateProduct}
            />

            <SupplierModal
                show={showSupplierModal}
                onClose={handleCloseSupplierModal}
            />
        </div>
    );
}

export default Estoque; 