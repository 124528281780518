import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { FaHome, FaCalendarAlt, FaHistory, FaMicrophone, FaUserFriends, FaBars, FaTimes, FaCog, FaCashRegister, FaSignOutAlt, FaBoxes, FaChartBar } from 'react-icons/fa';
import { useAuth } from '../../hooks/useAuth';
import { Auth } from 'aws-amplify';
import { USER_GROUPS } from '../../config/permissions';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const { userGroups } = useAuth();
    const navigate = useNavigate();
    const isAdmin = userGroups.includes(USER_GROUPS.ADMIN);
    const canAccessBooking = userGroups.includes(USER_GROUPS.ADMIN) || userGroups.includes(USER_GROUPS.ATENDIMENTOS) || userGroups.includes(USER_GROUPS.CAIXA);
    const canAccessPacientes = userGroups.includes(USER_GROUPS.ADMIN) || userGroups.includes(USER_GROUPS.ATENDIMENTOS);
    const canAccessCaixa = userGroups.includes(USER_GROUPS.ADMIN) || userGroups.includes(USER_GROUPS.CAIXA);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate('/');
            window.location.reload();
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    // Show at least the sign-out button for authenticated users
    const showMinimalSidebar = !isAdmin && !userGroups.includes(USER_GROUPS.ATENDIMENTOS) && !userGroups.includes(USER_GROUPS.CAIXA);
    
    if (showMinimalSidebar) {
        return (
            <>
                <button className="sidebar-toggle" onClick={toggleSidebar}>
                    {isOpen ? <FaTimes /> : <FaBars />}
                </button>
                <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                    <button className="sidebar-item signout-button" onClick={handleSignOut}>
                        <FaSignOutAlt className="sidebar-icon" />
                        <span>Sair</span>
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                {isAdmin && (
                    <>
                        <Link to="/anamnese" className="sidebar-item" onClick={closeSidebar}>
                            <FaMicrophone className="sidebar-icon" />
                            <span>Anamnese</span>
                        </Link>
                        <Link to="/historico" className="sidebar-item" onClick={closeSidebar}>
                            <FaHistory className="sidebar-icon" />
                            <span>Histórico</span>
                        </Link>
                    </>
                )}
                {canAccessBooking && (
                    <>
                        <Link to="/agenda" className="sidebar-item" onClick={closeSidebar}>
                            <FaCalendarAlt className="sidebar-icon" />
                            <span>Agendamentos</span>
                        </Link>
                    </>
                )}
                {canAccessPacientes && (
                    <>
                        <Link to="/pacientes" className="sidebar-item" onClick={closeSidebar}>
                            <FaUserFriends className="sidebar-icon" />
                            <span>Pacientes</span>
                        </Link>
                    </>
                )}
                {canAccessCaixa && (
                    <>
                        <Link to="/financeiro" className="sidebar-item" onClick={closeSidebar}>
                            <FaCashRegister className="sidebar-icon" />
                            <span>Financeiro</span>
                        </Link>
                        <Link to="/estoque" className="sidebar-item" onClick={closeSidebar}>
                            <FaBoxes className="sidebar-icon" />
                            <span>Estoque</span>
                        </Link>
                        <Link to="/reports" className="sidebar-item" onClick={closeSidebar}>
                            <FaChartBar className="sidebar-icon" />
                            <span>Relatórios</span>
                        </Link>
                    </>
                )}
                <Link to="/config" className="sidebar-item" onClick={closeSidebar}>
                    <FaCog className="sidebar-icon" />
                    <span>Configurações</span>
                </Link>
                <button className="sidebar-item signout-button" onClick={handleSignOut}>
                    <FaSignOutAlt className="sidebar-icon" />
                    <span>Sair</span>
                </button>
            </div>
        </>
    );
}

export default Sidebar;
