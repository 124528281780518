import React, { useState, useEffect } from 'react';
import { fetchBookings, fetchProfessionals, createPayment, fetchPayments, updatePayment,updateBooking, getFileFromS3 } from '../api';
import { ClipLoader } from 'react-spinners';
import './Financeiro.css' ;
import PaymentModal from './PaymentModal';
import CommissionModal from './CommissionModal';
import PaymentInfoModal from './PaymentInfoModal';
import { FaPlus, FaTimes, FaFileDownload } from 'react-icons/fa';
import jsPDF from 'jspdf';


function ViewBookingModal({ booking, showModal, onClose, startDate, endDate, onUpdate }) {
  const [editedComission, setEditedComission] = useState(0);
  const [editedValorTotal, setEditedValorTotal] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

    // console.log(booking);
  useEffect(() => {
    if (booking) {
      setEditedComission(booking.comission || 0);
      setEditedValorTotal(parseFloat(booking.valorTotal) || 0);
    }
  }, [booking]);

  useEffect(() => {
    if (showModal) {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal, onClose]);

  const handleUpdateComission = async () => {
    try {
      setIsUpdating(true);
      await updateBooking({
        atendimento_id: booking.atendimento_id,
        comission: editedComission,
        valorTotal: editedValorTotal
      });
      onUpdate(); // Call the update callback
      onClose();
    } catch (error) {
      console.error('Error updating booking:', error);
      alert('Failed to update booking');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDownloadReceipt = async () => {
    try {
      setIsDownloading(true);
      await getFileFromS3(booking.file_key, true);
    } catch (error) {
      console.error('Error downloading receipt:', error);
      alert('Failed to download receipt');
    } finally {
      setIsDownloading(false);
    }
  };

  if (!showModal || !booking) return null;

  console.log(booking);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content dark" onClick={e => e.stopPropagation()}>
        <h2>Detalhes do Agendamento</h2>
        <div className="booking-details">
          <div className="form-group">
            <label>Paciente</label>
            <div className="detail-value">{booking.paciente}</div>
          </div>

          <div className="form-group">
            <label>Data e Hora</label>
            <div className="detail-value">
              {new Date(booking.dataAtendimento).toLocaleString()}
            </div>
          </div>

          <div className="form-group">
            <label>Duração (minutos)</label>
            <div className="detail-value">{booking.duration}</div>
          </div>

          <div className="form-group">
            <label>Procedimentos</label>
            <div className="detail-value">
              {booking.procedimentos?.map((proc, i) => (
                <div key={i}>{proc.nome}</div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>Valor Total</label>
            <div className="detail-value editable">
              <input
                type="number"
                step="0.01"
                value={editedValorTotal}
                onChange={(e) => setEditedValorTotal(parseFloat(e.target.value) || 0)}
                className="commission-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Comissão</label>
            <div className="detail-value editable">
              <input
                type="number"
                step="0.01"
                value={editedComission}
                onChange={(e) => setEditedComission(parseFloat(e.target.value) || 0)}
                className="commission-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Status do Atendimento</label>
            <div className="detail-value">{booking.status || 'Agendado'}</div>
          </div>

          <div className="form-group">
            <label>Forma de Pagamento</label>
            <div className="detail-value">{booking.paymentMethod || 'Não definido'}</div>
          </div>

          <div className="form-group">
            <label>Status do Pagamento</label>
            <div className="detail-value">{booking.paymentStatus || 'Pendente'}</div>
          </div>

          {booking.paymentDate && (
            <div className="form-group">
              <label>Data do Pagamento</label>
              <div className="detail-value">
                {new Date(booking.paymentDate).toLocaleString()}
              </div>
            </div>
          )}

          {booking.observacao && (
            <div className="form-group">
              <label>Observação</label>
              <div className="detail-value">{booking.observacao}</div>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button 
            className="action-button update" 
            onClick={handleUpdateComission}
            disabled={isUpdating}
          >
            {isUpdating ? 'Atualizando...' : 'Atualizar Comissão'}
          </button>
          {booking.file_key && (
            <button 
              className="action-button" 
              onClick={handleDownloadReceipt}
              disabled={isDownloading}
            >
              {isDownloading ? 'Baixando...' : 'Baixar Comprovante'}
            </button>
          )}
          <button className="action-button" onClick={onClose}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

function Financeiro() {
  const [activeTab, setActiveTab] = useState('comissoes');
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [comissionStatus, setComissionStatus] = useState('unpaid');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('sent');
  const [paymentStartDate, setPaymentStartDate] = useState('');
  const [paymentEndDate, setPaymentEndDate] = useState('');
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const [invoicesError, setError] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);

  useEffect(() => {
    fetchProfessionals()
      .then(data => {
        const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setProfessionals(professionalsList);
      })
      .catch(error => console.error('Error fetching professionals:', error));

    // Load today's bookings with proper date formatting
    const queryParams = {
      startDate: `${startDate}T00:00:00Z`,
      endDate: `${endDate}T23:59:59Z`,
      comissionStatus: comissionStatus
    };
    loadBookings(queryParams);

    // Set default dates to current week's Monday and Friday
    const now = new Date();
    const monday = new Date(now);
    monday.setDate(now.getDate() - now.getDay() + 1);
    const friday = new Date(now);
    friday.setDate(now.getDate() - now.getDay() + 5);
    
    setPaymentStartDate(monday.toISOString().split('T')[0]);
    setPaymentEndDate(friday.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    if (activeTab === 'pagamentos') {
      loadPayments();
    }
  }, [activeTab, paymentStatus, paymentStartDate, paymentEndDate]);

  const loadBookings = async (queryParams = {}) => {
    try {
      setBookingsLoading(true);
      
      // Format dates to match backend expectations
      const formattedParams = {
        ...queryParams
      };

      if (queryParams.startDate) {
        formattedParams.startDate = queryParams.startDate;
        formattedParams.endDate = queryParams.endDate;
      }

      if (comissionStatus) {
        formattedParams.comissionStatus = comissionStatus;
      }

      const bookingsData = await fetchBookings(formattedParams);
      setBookings(bookingsData);
      console.log(bookingsData);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setBookingsLoading(false);
    }
  };

  const loadPayments = async () => {
    try {
      setPaymentsLoading(true);
      const filters = {
        status: paymentStatus,
        start_date: `${paymentStartDate}T00:00:00Z`,
        end_date: `${paymentEndDate}T23:59:59Z`
      };
      const data = await fetchPayments(filters);
      setPayments(data);
    } catch (error) {
      console.error('Error loading payments:', error);
    } finally {
      setPaymentsLoading(false);
    }
  };

  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert('Por favor, selecione as datas de início e fim');
      return;
    }

    const queryParams = {
      startDate: `${startDate}T00:00:00Z`,
      endDate: `${endDate}T23:59:59Z`
    };

    loadBookings(queryParams);
  };

  const calculateTotalValue = (procedures) => {
    return procedures.reduce((total, proc) => total + proc.valor, 0);
  };

  const groupBookingsByProfessional = () => {
    const groupedBookings = {};
    
    bookings
      .filter(booking => booking.status === 'Agendado' || booking.status === 'Realizado')
      .forEach(booking => {
        const profId = booking.profissional;
        if (!groupedBookings[profId]) {
          groupedBookings[profId] = {
            bookings: [],
            total: 0,
            totalComission: 0
          };
        }
        const bookingTotal = calculateTotalValue(booking.procedimentos);
        groupedBookings[profId].bookings.push(booking);
        groupedBookings[profId].total += bookingTotal;
        groupedBookings[profId].totalComission += booking.comission || 0;
      });
    
    return groupedBookings;
  };

  const getProfessionalName = (professionalId) => {
    const professional = professionals.find(p => p.id === professionalId);
    return professional ? professional.name : professionalId;
  };

  const handleRowClick = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleQuickFilter = (days) => {
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - days);
    
    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);
    
    loadBookings({
      startDate: `${start.toISOString().split('T')[0]}T00:00:00Z`,
      endDate: `${end.toISOString().split('T')[0]}T23:59:59Z`
    });
  };

  const handleCreatePayment = async (paymentData) => {
    try {
      await createPayment(paymentData);
      loadPayments(); // Refresh the payments list
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletePayment = async (paymentId) => {
    if (window.confirm('Quer mesmo deletar o pagamento?')) {
      try {
        await updatePayment(paymentId, {}, true);
        // Refresh payments list
        loadPayments();
      } catch (err) {
        setError('Failed to delete payment');
        console.error(err);
      }
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    
    // Title
    doc.setFontSize(16);
    doc.text(`Movimento Caixa ${startDate}`, pageWidth / 2, 20, { align: 'center' });
    
    let yPos = 40;
    const lineHeight = 10;
    const colWidths = {
      nome: 60,
      procedimento: 60,
      pagamento: 25,
      valor: 25,
      observacao: 20
    };
    
    // Set line width for borders
    doc.setLineWidth(0.1);
    
    // Headers with borders
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    
    // Draw header cells with borders
    let xPos = 10;
    
    // NOME header
    doc.rect(xPos, yPos - 5, colWidths.nome, lineHeight);
    doc.text('NOME', xPos + 2, yPos);
    xPos += colWidths.nome;
    
    // PROCEDIMENTO header
    doc.rect(xPos, yPos - 5, colWidths.procedimento, lineHeight);
    doc.text('PROCEDIMENTO', xPos + 2, yPos);
    xPos += colWidths.procedimento;
    
    // PAGAMENTO header
    doc.rect(xPos, yPos - 5, colWidths.pagamento, lineHeight);
    doc.text('PAGAMENTO', xPos + 2, yPos);
    xPos += colWidths.pagamento;
    
    // VALOR ENTRADA header
    doc.rect(xPos, yPos - 5, colWidths.valor, lineHeight);
    doc.text('VALOR', xPos + 2, yPos);
    xPos += colWidths.valor;
    
    // OBSERVAÇÃO header
    // doc.rect(xPos, yPos - 5, colWidths.observacao, lineHeight);
    // doc.text('OBS', xPos + 2, yPos);
    
    yPos += lineHeight;
    
    // Data
    doc.setFont('helvetica', 'normal');
    Object.entries(groupBookingsByProfessional()).forEach(([profId, data]) => {
      data.bookings.forEach(booking => {
        if (yPos > 270) { // Check if we need a new page
          doc.addPage();
          yPos = 20;
        }
        
        // Format name to show only first and second names
        const names = booking.paciente.split(' ');
        const formattedName = names.length > 1 ? 
          `${names[0]} ${names[1]}` : 
          names[0];
        
        // Draw data cells with borders
        xPos = 10;
        
        // NOME cell
        doc.rect(xPos, yPos - 5, colWidths.nome, lineHeight);
        doc.text(formattedName.substring(0, 25), xPos + 2, yPos);
        xPos += colWidths.nome;
        
        // PROCEDIMENTO cell
        doc.rect(xPos, yPos - 5, colWidths.procedimento, lineHeight);
        doc.text(booking.procedimentos[0]?.nome.substring(0, 25) || '-', xPos + 2, yPos);
        xPos += colWidths.procedimento;
        
        // PAGAMENTO cell
        doc.rect(xPos, yPos - 5, colWidths.pagamento, lineHeight);
        // doc.text('CRÉDITO', xPos + 2, yPos);
        doc.text(booking.paymentMethod || '', xPos + 2, yPos);
        xPos += colWidths.pagamento;
        
        // VALOR ENTRADA cell
        doc.rect(xPos, yPos - 5, colWidths.valor, lineHeight);
        doc.text(`R$ ${(parseFloat(booking.valorTotal) || 0)}`, xPos + 2, yPos);
        xPos += colWidths.valor;
        
        // OBSERVAÇÃO cell
        // doc.rect(xPos, yPos - 5, colWidths.observacao, lineHeight);
        // doc.text(booking.observacao || '', xPos + 2, yPos);
        
        yPos += lineHeight;
      });
    });
    
    // Save PDF
    doc.save(`movimento-caixa-${startDate}.pdf`);
  };

  return (
    <div className="financeiro-container">
      <div className="financeiro-tabs">
        <button 
          className={`tab-button ${activeTab === 'comissoes' ? 'active' : ''}`}
          onClick={() => setActiveTab('comissoes')}
        >
          Comissões
        </button>
        <button 
          className={`tab-button ${activeTab === 'pagamentos' ? 'active' : ''}`}
          onClick={() => setActiveTab('pagamentos')}
        >
          Pagamentos
        </button>
      </div>

      {activeTab === 'comissoes' && (
        <div className="caixa-container">
          <div className="caixa-search-filters">
            <div className="date-filters">
              <button 
                className="report-button"
                onClick={generatePDF}
              >
                <FaFileDownload /> Gerar Relatório
              </button>
              <div className="quick-filters">
                <button 
                  className="quick-filter-button"
                  onClick={() => handleQuickFilter(7)}
                >
                  7d
                </button>
                <button 
                  className="quick-filter-button"
                  onClick={() => handleQuickFilter(15)}
                >
                  15d
                </button>
              </div>
              <div className="date-input-group">
                <label>Início:</label>
                <input 
                  type="date" 
                  value={startDate} 
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setBookings([]); // Clear current bookings
                  }}
                  className="date-input"
                  required
                />
              </div>
              <div className="date-input-group">
                <label>Fim:</label>
                <input 
                  type="date" 
                  value={endDate} 
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setBookings([]); // Clear current bookings
                  }}
                  className="date-input"
                  required
                />
              </div>
              <div className="date-input-group">
                <label>Status da Comissão:</label>
                <select
                  value={comissionStatus}
                  onChange={(e) => {
                    setComissionStatus(e.target.value);
                    setBookings([]); // Clear current bookings
                  }}
                  className="status-select"
                >
                  <option value="unpaid">Aberta</option>
                  <option value="sent">Enviada para pagamento</option>
                </select>
              </div>
              <button 
                className="dark-button"
                onClick={handleSearch}
                disabled={bookingsLoading}
              >
                Filtrar
              </button>
            </div>
          </div>

          {bookingsLoading ? (
            <div className="loading-container">
              <ClipLoader size={50} color={"#4a90e2"} />
            </div>
          ) : (
            <div className="bookings-list">
              {Object.entries(groupBookingsByProfessional()).map(([profId, data]) => (
                <div key={profId} className="professional-group">
                  <h3>{getProfessionalName(profId)}</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Paciente</th>
                        <th>Procedimentos</th>
                        <th>Valor</th>
                        <th>Comissão</th>
                        <th>Forma de Pagamento</th>
                        <th>Pagamento</th>
                        <th>Procedimento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.bookings.map((booking, index) => {
                        const isCompleted = booking.status === 'Realizado';
                        const bookingTotal = parseInt(booking.valorTotal, 10);

                        return (
                          <tr 
                            key={index}
                            onClick={() => handleRowClick(booking)}
                            style={{ 
                              backgroundColor: isCompleted ? '#2e7d32' : 'inherit',
                              color: isCompleted ? '#ffffff' : 'inherit',
                              cursor: 'pointer'
                            }}
                          >
                            <td>{booking.paciente}</td>
                            <td>
                              {booking.procedimentos?.map((proc, i) => (
                                <div key={i}>{proc.nome}</div>
                              ))}
                            </td>
                            <td>R$ {(parseFloat(bookingTotal) || 0).toFixed(2)}</td>
                            <td>R$ {(booking.comission || 0).toFixed(2)}</td>
                            <td>{booking.paymentMethod || '-'}</td>
                            <td>{booking.paymentStatus || 'Pendente'}</td>
                            <td>{booking.status || 'Agendado'}</td>
                          </tr>
                        );
                      })}
                      <tr className="total-row">
                        <td colSpan="2"><strong>Total do Profissional</strong></td>
                        <td><strong>R$ {(data.bookings.reduce((sum, booking) => sum + (parseFloat(booking.valorTotal) || 0), 0)).toFixed(2)}</strong></td>
                        <td><strong>R$ {(data.totalComission || 0).toFixed(2)}</strong></td>
                        <td colSpan="3"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
              {Object.keys(groupBookingsByProfessional()).length > 0 && (
                <div className="grand-total">
                  <h3>Total Geral</h3>
                  <div className="total-value">
                    <div>
                      Valor: R$ {(Object.values(groupBookingsByProfessional())
                        .reduce((total, data) => 
                          total + data.bookings.reduce((sum, booking) => 
                            sum + (parseFloat(booking.valorTotal) || 0), 0), 0))
                        .toFixed(2)}
                    </div>
                    <div>
                      Comissões: R$ {(Object.values(groupBookingsByProfessional())
                        .reduce((total, data) => total + (data.totalComission || 0), 0))
                        .toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'pagamentos' && (
        <div className="pagamentos-container">
          <div className="table-actions">
            <button className="success" onClick={() => setShowPaymentModal(true)}>
              <FaPlus /> Adicionar Pagamento
            </button>
            <button className="success" onClick={() => setShowCommissionModal(true)}>
              Lançar Comissões
            </button>
          </div>
          <div className="caixa-search-filters">
            <div className="date-filters">
              <div className="date-input-group">
                <label>Data Inicial:</label>
                <input
                  type="date"
                  value={paymentStartDate}
                  onChange={(e) => setPaymentStartDate(e.target.value)}
                  className="date-input"
                  required
                />
              </div>
              <div className="date-input-group">
                <label>Data Final:</label>
                <input
                  type="date"
                  value={paymentEndDate}
                  onChange={(e) => setPaymentEndDate(e.target.value)}
                  className="date-input"
                  required
                />
              </div>
              <div className="date-input-group">
                <label>Status:</label>
                <select
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  className="status-select"
                >
                  <option value="sent">Aberta</option>
                  <option value="paid">Pago</option>
                </select>
              </div>
            </div>
          </div>

          {invoicesError && <div className="error-message">{invoicesError}</div>}
          {paymentsLoading ? (
            <div className="loading-container">
              <ClipLoader size={50} color={"#4a90e2"} />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  {/* <th>Data de criação</th> */}
                  <th>Data de vencimento</th>
                  <th>Descrição</th>
                  <th>Fornecedor</th>
                  <th>Valor Total</th>
                  <th>Deletar</th>



                </tr>
              </thead>
              <tbody>
                {payments.length === 0 ? <tr><td colSpan="6">No payments found</td></tr> : payments.map((payment) => (
                  <tr 
                    key={payment.payment_id}
                    className="clickable-row"
                    onClick={() => {
                      setSelectedPayment(payment);
                      setShowPaymentInfoModal(true);
                    }}
                  >
                    {/* <td>{new Date(payment.created_at).toLocaleDateString()}</td> */}
                    <td>{payment.expiration_date ? new Date(payment.expiration_date).toLocaleDateString() : '-'}</td>
                    <td>{payment.description || '-'}</td>
                    <td>{payment.supplier || '-'}</td>
                    <td>R$ {payment.amount ? parseFloat(payment.amount).toFixed(2) : '0.00'}</td>
                    <td>
                      <button 
                        className="delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeletePayment(payment.payment_id);
                        }}
                      >
                        <FaTimes />

                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      <ViewBookingModal
        booking={selectedBooking}
        showModal={showModal}
        startDate={startDate}
        endDate={endDate}
        onUpdate={() => {
          const queryParams = {
            startDate: `${startDate}T00:00:00Z`,
            endDate: `${endDate}T23:59:59Z`
          };
          loadBookings(queryParams);
        }}
        onClose={() => {
          setShowModal(false);
          setSelectedBooking(null);
        }}
      />

      <PaymentModal
        show={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onSubmit={handleCreatePayment}
      />

      <CommissionModal
        show={showCommissionModal}
        onClose={() => {
          setShowCommissionModal(false);
          loadPayments(); // Refresh payments after modal closes
        }}
      />

      <PaymentInfoModal
        show={showPaymentInfoModal}
        payment={selectedPayment}
        onClose={() => {
          setShowPaymentInfoModal(false);
          setSelectedPayment(null);
        }}
        onUpdate={() => loadPayments()}
      />
    </div>
  );
}

export default Financeiro; 