import React, { useState, useEffect } from 'react';
import { FaFileUpload } from 'react-icons/fa';
import './Financeiro.css';

function PaymentModal({ show, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        payment_type: 'other',
        amount: '',
        description: '',
        payment_method: '',
        expiration_date: '',
        customer_id: '',
        booking_id: '',
        file: null,
        file_name: '',
        supplier: ''
    });
    const [hasInstallments, setHasInstallments] = useState(false);
    const [installments, setInstallments] = useState(3);
    const [installmentRows, setInstallmentRows] = useState([]);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (show) {
            setFormData({
                payment_type: 'other',
                amount: '',
                description: '',
                payment_method: '',
                expiration_date: '',
                customer_id: '',
                booking_id: '',
                file: null,
                file_name: '',
                supplier: ''
            });
            setHasInstallments(false);
            setInstallments(3);
            setInstallmentRows([]);
        }
    }, [show]);

    useEffect(() => {
        if (hasInstallments && formData.amount && formData.expiration_date) {
            const baseAmount = parseFloat(formData.amount) / installments;
            const baseDate = new Date(formData.expiration_date);
            const newRows = [];
            
            for (let i = 0; i < installments; i++) {
                const date = new Date(baseDate);
                date.setMonth(date.getMonth() + i);
                newRows.push({
                    amount: baseAmount.toFixed(2),
                    date: date.toISOString().split('T')[0],
                    file: null,
                    file_name: ''
                });
            }
            setInstallmentRows(newRows);
        }
    }, [hasInstallments, formData.amount, formData.expiration_date, installments]);

    if (!show) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        try {
            if (hasInstallments) {
                for (let i = 0; i < installmentRows.length; i++) {
                    const row = installmentRows[i];
                    const installmentData = {
                        ...formData,
                        amount: row.amount,
                        expiration_date: row.date,
                        description: `${formData.description} - Parcela ${i + 1}/${installmentRows.length}`,
                        file: row.file,
                        file_name: row.file_name
                    };
                    await onSubmit(installmentData);
                }
            } else {
                await onSubmit(formData);
            }
            onClose();
        } catch (error) {
            console.error('Error creating payment:', error);
        } finally {
            setProcessing(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleInstallmentRowChange = (index, field, value) => {
        setInstallmentRows(prev => {
            const newRows = [...prev];
            newRows[index] = { ...newRows[index], [field]: value };
            return newRows;
        });
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                setFormData(prev => ({
                    ...prev,
                    file: base64,
                    file_name: file.name
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleInstallmentFileUpload = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                handleInstallmentRowChange(index, 'file', base64);
                handleInstallmentRowChange(index, 'file_name', file.name);
            };
            reader.readAsDataURL(file);
        }
    };

    const canShowInstallments = hasInstallments && formData.amount && formData.expiration_date;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content dark" onClick={e => e.stopPropagation()}>
                <h2>Criar Pagamento</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Tipo de Pagamento</label>
                        <select
                            name="payment_type"
                            value={formData.payment_type}
                            onChange={handleChange}
                            required
                        >
                            <option value="other">Outros</option>
                            <option value="nf">NF</option>
                            <option value="comissions">Comissões</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>
                            Valor Total <span className="required">*</span>
                        </label>
                        <input
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            required
                            step="0.01"
                            min="0"
                        />
                    </div>

                    <div className="form-group">
                        <label>Fornecedor <span className="required">*</span></label>
                        <input
                            type="text"
                            name="supplier"
                            value={formData.supplier}
                            onChange={handleChange}
                            placeholder="Nome do fornecedor"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Descrição</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            Data de Vencimento <span className="required">*</span>
                        </label>
                        <input
                            type="date"
                            name="expiration_date"
                            value={formData.expiration_date}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group checkbox-group">
                        <label className="checkbox-container">
                            <input
                                type="checkbox"
                                checked={hasInstallments}
                                onChange={(e) => setHasInstallments(e.target.checked)}
                            />
                            <span className="checkmark"></span>
                            Parcelar
                        </label>
                    </div>

                    {hasInstallments && !canShowInstallments && (
                        <div className="alert-message">
                            Por favor, preencha o valor total e a data de vencimento para visualizar as parcelas.
                        </div>
                    )}

                    {!hasInstallments && (
                        <div className="form-group">
                            <label>Anexar Arquivo:</label>
                            <div className="file-upload-wrapper">
                                <input
                                    type="file"
                                    id="file-upload"
                                    onChange={handleFileUpload}
                                    accept=".pdf"
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-upload" className="file-upload-label">
                                    <FaFileUpload /> {formData.file_name || 'Selecionar arquivo'}
                                </label>
                            </div>
                        </div>
                    )}

                    {canShowInstallments && (
                        <>
                            <div className="form-group">
                                <label>Número de Parcelas</label>
                                <input
                                    type="number"
                                    value={installments}
                                    onChange={(e) => setInstallments(parseInt(e.target.value))}
                                    min="2"
                                    max="12"
                                />
                            </div>

                            <div className="installments-table">
                                {installmentRows.map((row, index) => (
                                    <div key={index} className="installment-row">
                                        <input
                                            type="date"
                                            value={row.date}
                                            onChange={(e) => handleInstallmentRowChange(index, 'date', e.target.value)}
                                        />
                                        <input
                                            type="number"
                                            value={row.amount}
                                            onChange={(e) => handleInstallmentRowChange(index, 'amount', e.target.value)}
                                            step="0.01"
                                            min="0"
                                        />
                                        <div className="file-upload-wrapper">
                                            <input
                                                type="file"
                                                id={`file-upload-${index}`}
                                                onChange={(e) => handleInstallmentFileUpload(index, e)}
                                                accept=".pdf"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor={`file-upload-${index}`} className="file-upload-label">
                                                <FaFileUpload /> {row.file_name || `Arquivo Parcela ${index + 1}`}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className="installments-total">
                                    <span>Total das Parcelas:</span>
                                    <span className="total-amount">
                                        R$ {installmentRows.reduce((sum, row) => sum + parseFloat(row.amount || 0), 0).toFixed(2)}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="modal-actions">
                        <button 
                            type="submit" 
                            className="success" 
                            disabled={processing}
                        >
                            {processing ? 'Processando...' : 'Criar Pagamento'}
                        </button>
                        <button type="button" onClick={onClose} disabled={processing}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PaymentModal; 