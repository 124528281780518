import React, { useState, useEffect } from 'react';
import { FaTrash, FaFileUpload } from 'react-icons/fa';
import ProductSearchSelect from '../ProductSearchSelect/ProductSearchSelect';
import SupplierSearchSelect from '../SupplierSearchSelect/SupplierSearchSelect';
import './Estoque.css';

function InvoiceModal({ show, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        nf_number: '',
        date: new Date().toISOString().split('T')[0],
        expiration_date: '',
        supplier: '',
        supplier_id: '',
        notes: '',
        taxes: '',
        shipping: '',
        items: [],
        file: null,
        file_name: ''
    });
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [hasInstallments, setHasInstallments] = useState(false);
    const [installments, setInstallments] = useState(3);
    const [installmentRows, setInstallmentRows] = useState([]);

    useEffect(() => {
        if (!show) {
            setFormData({
                nf_number: '',
                date: new Date().toISOString().split('T')[0],
                expiration_date: '',
                supplier: '',
                supplier_id: '',
                notes: '',
                taxes: '',
                shipping: '',
                items: [],
                file: null,
                file_name: ''
            });
            setSelectedQuantity(1);
            setSelectedPrice('');
            setError('');
            setHasInstallments(false);
            setInstallments(3);
            setInstallmentRows([]);
        }
    }, [show]);

    useEffect(() => {
        if (hasInstallments && formData.expiration_date) {
            const totalValue = formData.items.reduce((sum, item) => sum + (item.quantity * item.price), 0) + 
                             (parseFloat(formData.shipping) || 0) + 
                             (parseFloat(formData.taxes) || 0);
            
            const baseAmount = totalValue / installments;
            const baseDate = new Date(formData.expiration_date);
            const newRows = [];
            
            for (let i = 0; i < installments; i++) {
                const date = new Date(baseDate);
                date.setMonth(date.getMonth() + i);
                newRows.push({
                    amount: baseAmount.toFixed(2),
                    date: date.toISOString().split('T')[0],
                    file: null,
                    file_name: ''
                });
            }
            setInstallmentRows(newRows);
        }
    }, [hasInstallments, formData.expiration_date, formData.items, formData.shipping, formData.taxes, installments]);

    // Add keyboard event handler
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleProductSelect = (item) => {
        if (!selectedPrice) {
            setError('Preencha o preço');
            return;
        }

        setFormData(prev => ({
            ...prev,
            items: [
                ...prev.items,
                {
                    product_id: item.product_id,
                    description: item.description,
                    quantity: parseInt(selectedQuantity),
                    price: parseFloat(selectedPrice)
                }
            ]
        }));

        setSelectedQuantity(1);
        setSelectedPrice('');
        setError('');
    };

    const handleRemoveItem = (index) => {
        setFormData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Remove data URL prefix for base64 encoding
                const base64 = reader.result.split(',')[1];
                setFormData(prev => ({
                    ...prev,
                    file: base64,
                    file_name: file.name
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleInstallmentRowChange = (index, field, value) => {
        setInstallmentRows(prev => {
            const newRows = [...prev];
            newRows[index] = { ...newRows[index], [field]: value };
            return newRows;
        });
    };

    const handleInstallmentFileUpload = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                handleInstallmentRowChange(index, 'file', base64);
                handleInstallmentRowChange(index, 'file_name', file.name);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.items.length === 0) {
            alert('Adicione pelo menos um item à nota fiscal');
            return;
        }

        const totalValue = formData.items.reduce((sum, item) => sum + (item.quantity * item.price), 0) + 
                         (parseFloat(formData.shipping) || 0) + 
                         (parseFloat(formData.taxes) || 0);

        // Prepare payments data
        let payments = [];
        if (hasInstallments) {
            // Add all installments to payments array
            payments = installmentRows.map((row, index) => ({
                date: row.date,
                amount: parseFloat(row.amount),
                file: row.file,
                file_name: row.file_name,
                install_payment: true,
                last_payment: index === installmentRows.length - 1
            }));
        } else {
            // Single payment
            payments = [{
                date: formData.expiration_date,
                amount: totalValue,
                file: formData.file,
                file_name: formData.file_name,
                install_payment: false,
                last_payment: true
            }];
        }

        const dataToSubmit = {
            ...formData,
            payments
        };

        setProcessing(true);
        try {
            await onSubmit(dataToSubmit);
            console.log('Submitted data:', dataToSubmit);
        } catch (error) {
            setError('Erro ao criar nota fiscal');
            console.error(error);
        } finally {
            setProcessing(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className="modal-overlay" 
            onClick={handleOverlayClick}
        >
            <div className="modal-content">
                <h2>Nova Nota Fiscal</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Número da NF: <span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                value={formData.nf_number}
                                onChange={(e) => setFormData(prev => ({ ...prev, nf_number: e.target.value }))}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Data de Entrada: <span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="date"
                                value={formData.date}
                                onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Data da Nota: <span style={{ color: 'red' }}>*</span></label>
                        <input
                            type="date"
                            value={formData.expiration_date}
                            onChange={(e) => setFormData(prev => ({ ...prev, expiration_date: e.target.value }))}
                            required
                            placeholder="Data de validade"
                            onClick={(e) => e.target.showPicker()}
                        />
                    </div>

                    <div className="form-group">
                        <label>Fornecedor: <span style={{ color: 'red' }}>*</span></label>
                        <SupplierSearchSelect
                            onSupplierSelect={(supplier) => setFormData(prev => ({ 
                                ...prev, 
                                supplier: supplier.name,
                                supplier_id: supplier.supplier_id
                            }))}
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Impostos (R$):</label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={formData.taxes}
                                onChange={(e) => setFormData(prev => ({ ...prev, taxes: e.target.value }))}
                                placeholder="Opcional"
                            />
                        </div>

                        <div className="form-group">
                            <label>Frete (R$):</label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={formData.shipping}
                                onChange={(e) => setFormData(prev => ({ ...prev, shipping: e.target.value }))}
                                placeholder="Opcional"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Observações:</label>
                        <textarea
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                            placeholder="Opcional"
                        />
                    </div>

                    <div className="form-group">
                        <label>Adicionar Item: <span style={{ color: 'red' }}>*</span></label>
                        {error && <div className="error-message">{error}</div>}
                        <div className="add-item-row">
                            <div className="product-search-wrapper">
                                <ProductSearchSelect
                                    onSelect={handleProductSelect}
                                    placeholder="Buscar produto..."
                                />
                            </div>
                            <input
                                type="number"
                                min="1"
                                value={selectedQuantity}
                                onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                                className="quantity-input"  
                                placeholder="Qtd"
                                required
                            />
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={selectedPrice}
                                onChange={(e) => setSelectedPrice(e.target.value)}
                                className="price-input"
                                placeholder="Preço R$"
                                
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Anexar Arquivo:</label>
                        <div className="file-upload-wrapper">
                            <input
                                type="file"
                                id="file-upload"
                                onChange={handleFileUpload}
                                accept=".pdf"
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="file-upload" className="file-upload-label">
                                <FaFileUpload /> {formData.file_name || 'Selecionar arquivo'}
                            </label>
                        </div>
                    </div>

                    <div className="form-group checkbox-group">
                        <label className="checkbox-container">
                            <input
                                type="checkbox"
                                checked={hasInstallments}
                                onChange={(e) => setHasInstallments(e.target.checked)}
                            />
                            <span className="checkmark"></span>
                            Parcelar
                        </label>
                    </div>

                    {hasInstallments && formData.items.length > 0 && formData.expiration_date && (
                        <>
                            <div className="form-group">
                                <label>Número de Parcelas</label>
                                <input
                                    type="number"
                                    value={installments}
                                    onChange={(e) => setInstallments(parseInt(e.target.value))}
                                    min="2"
                                    max="12"
                                />
                            </div>

                            <div className="installments-table">
                                {installmentRows.map((row, index) => (
                                    <div key={index} className="installment-row">
                                        <input
                                            type="date"
                                            value={row.date}
                                            onChange={(e) => handleInstallmentRowChange(index, 'date', e.target.value)}
                                        />
                                        <input
                                            type="number"
                                            value={row.amount}
                                            onChange={(e) => handleInstallmentRowChange(index, 'amount', e.target.value)}
                                            step="0.01"
                                            min="0"
                                        />
                                        <div className="file-upload-wrapper">
                                            <input
                                                type="file"
                                                id={`file-upload-${index}`}
                                                onChange={(e) => handleInstallmentFileUpload(index, e)}
                                                accept=".pdf"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor={`file-upload-${index}`} className="file-upload-label">
                                                <FaFileUpload /> {row.file_name || `Arquivo Parcela ${index + 1}`}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className="installments-total">
                                    <span>Total das Parcelas:</span>
                                    <span className="total-amount">
                                        R$ {installmentRows.reduce((sum, row) => sum + parseFloat(row.amount || 0), 0).toFixed(2)}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="items-list">
                        <h3>Itens da Nota Fiscal</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantidade</th>
                                    <th>Preço Unit.</th>
                                    <th>Total</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.description}</td>
                                        <td>{item.quantity}</td>
                                        <td>R$ {item.price.toFixed(2)}</td>
                                        <td>R$ {(item.quantity * item.price).toFixed(2)}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="danger"
                                                onClick={() => handleRemoveItem(index)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-actions">
                        <button 
                            type="submit" 
                            className="success" 
                            disabled={processing}
                            style={{ minWidth: '150px' }}
                        >
                            {processing ? (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
                                    <div className="loading-spinner" style={{ 
                                        width: '20px', 
                                        height: '20px',
                                        border: '2px solid rgba(255, 255, 255, 0.3)',
                                        borderTop: '2px solid #fff',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite'
                                    }} />
                                    <span>Processando...</span>
                                </div>
                            ) : (
                                'Criar Nota Fiscal'
                            )}
                        </button>
                        <button type="button" onClick={onClose} disabled={processing}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default InvoiceModal; 