import React, { useState, useEffect, useRef } from 'react';
import PacientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import { fetchProcedimentos, fetchProfessionals, fetchBookings, updateBooking, createBooking } from '../api';
import { ClipLoader } from 'react-spinners';
import { FaCalendarAlt } from 'react-icons/fa';
import EditBookingModal from './EditBookingModal';
import { USER_GROUPS } from '../../config/permissions';
import { useAuth } from '../../hooks/useAuth';
import './Booking.css';

function Booking() {
  const { user } = useAuth();
  const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
  const isCaixa = userGroups.includes(USER_GROUPS.CAIXA);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState('30');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [showSubProcedures, setShowSubProcedures] = useState(false);
  const [currentProcedure, setCurrentProcedure] = useState(null);
  const [generalObservation, setGeneralObservation] = useState('');
  const [professionals, setProfessionals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState('create');
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [showMissingFieldsModal, setShowMissingFieldsModal] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  
  // Ref for patient search component
  const patientSearchRef = useRef(null);

  // New state for bookings search
  const [searchProfessional, setSearchProfessional] = useState(null);
  const [searchPatient, setSearchPatient] = useState(null);
  const [searchDate, setSearchDate] = useState('');
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    fetchProcedimentos()
      .then(data => {
        const proceduresList = Object.entries(data.config.procedimentos).map(([key, value]) => ({
          name: key,
          ...value  
        }));
        setProcedures(proceduresList);
      })
      .catch(error => console.error('Error fetching procedures:', error));

    fetchProfessionals()
      .then(data => {
        const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setProfessionals(professionalsList);
      })
      .catch(error => console.error('Error fetching professionals:', error));

    // Fetch initial bookings for today
    const today = new Date().toISOString().split('T')[0];
    setSearchDate(today);
    console.log("Fetching atendimentos by default, from query parms ", { date: today });
    loadBookings({ date: today });

    // If user is CAIXA, force view tab
    if (isCaixa) {
      setActiveTab('view');
    }
  }, [isCaixa]);

  const handlePatientSelect = (patient) => {
    console.log(patient);
    setSelectedPatient(patient);
  };

  const handleProcedureSelect = (procedure) => {
    if (procedure.subcategorias) {
      setCurrentProcedure(procedure);
      setShowSubProcedures(true);
    } else {
      addProcedure(procedure.name, null, procedure.valor);
    }
  };

  const handleSubProcedureSelect = (subProcedureName, subProcedure) => {
    addProcedure(currentProcedure.name, subProcedureName, subProcedure.valor);
    setShowSubProcedures(false);
    setCurrentProcedure(null);
  };

  const addProcedure = (procedureName, subProcedureName, value) => {
    const newProcedure = {
      procedure: procedureName,
      subProcedure: subProcedureName,
      value: value,
    };
    setSelectedProcedures([...selectedProcedures, newProcedure]);
  };

  const updateProcedure = (index, field, value) => {
    const updatedProcedures = [...selectedProcedures];
    updatedProcedures[index] = {
      ...updatedProcedures[index],
      [field]: value
    };
    setSelectedProcedures(updatedProcedures);
  };

  const removeProcedure = (index) => {
    const updatedProcedures = selectedProcedures.filter((_, i) => i !== index);
    setSelectedProcedures(updatedProcedures);
  };

  const renderSelectedProcedures = () => {
    return selectedProcedures.map((proc, index) => (
      <div key={index} className="selected-procedure-item">
        <div className="procedure-details">
          <span>{proc.procedure}</span>
          {proc.subProcedure && <span> - {proc.subProcedure}</span>}
          
          <div className="procedure-value-edit">
            <label>Valor R$:</label>
            <input 
              type="number" 
              value={proc.value} 
              onChange={(e) => updateProcedure(index, 'value', parseFloat(e.target.value))}
              step="0.01"
              min="0"
            />
          </div>
        </div>
        <button onClick={() => removeProcedure(index)}>×</button>
      </div>
    ));
  };

  const filteredProcedures = procedures.filter(proc => 
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const checkMissingFields = () => {
    const missing = [];
    if (!selectedProfessional) missing.push('Profissional');
    if (!selectedDate) missing.push('Data');
    if (!selectedHour || !selectedMinute) missing.push('Horário');
    if (!selectedDuration) missing.push('Duração');
    if (!selectedPatient) missing.push('Paciente');
    if (selectedProcedures.length === 0) missing.push('Procedimentos');
    return missing;
  };

  const handleBooking = async () => {
    const missingFieldsList = checkMissingFields();
    
    if (missingFieldsList.length > 0) {
      setMissingFields(missingFieldsList);
      setShowMissingFieldsModal(true);
      setTimeout(() => setShowMissingFieldsModal(false), 3000);
      return;
    }
    
    const formattedTime = `${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}`;
    const bookingData = {
      professionalId: selectedProfessional.id,
      patientId: selectedPatient.pacientId,
      dataAtendimento: `${selectedDate}T${formattedTime}:00Z`,
      duration: parseInt(selectedDuration),
      procedimentos: selectedProcedures.map(proc => ({
        nome: proc.subProcedure ? `${proc.procedure} - ${proc.subProcedure}` : proc.procedure,
        valor: proc.value
      })),
      observacao: generalObservation
    };

    try {
      setIsLoading(true);
      const result = await createBooking(bookingData);
      setSuccessMessage('Agendamento criado com sucesso!');
      resetForm();
    } catch (error) {
      console.error('Booking creation failed:', error);
      setSuccessMessage('Erro ao criar agendamento. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };

  const resetForm = () => {
    setSelectedProfessional(null);
    setSelectedDate(null);
    setSelectedHour('');
    setSelectedMinute('');
    setSelectedDuration('30');
    setSelectedPatient(null);
    setSelectedProcedures([]);
    setGeneralObservation('');
    setSearchTerm('');
    
    const dateInput = document.querySelector('input[type="date"]');
    if (dateInput) {
      dateInput.value = '';
    }

    if (patientSearchRef.current) {
      patientSearchRef.current.resetSearch();
    }
  };

  const loadBookings = async (queryParams = {}) => {
    try {
      setBookingsLoading(true);
      const bookingsData = await fetchBookings(queryParams);
      setBookings(bookingsData);
      console.log(bookingsData);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setBookingsLoading(false);
    }
  };

  const handleSearch = () => {
    const queryParams = {};
    
    if (searchProfessional) {
      queryParams.professionalId = searchProfessional.id;
    }
    
    if (searchPatient) {
      queryParams.patientId = searchPatient.pacientId;
    }
    
    if (searchDate) {
      queryParams.date = searchDate;
    }

    console.log("Fetching atendimentos from query parms ", queryParams)
    loadBookings(queryParams);
  };

  const handleEditBooking = (booking) => {
    setSelectedBooking(booking);
    console.log("Editing booking:", booking);
    setEditFormData({
      title: booking.title,
      dataAtendimento: booking.datetimeAtendimento,
      duration: booking.duration,
      procedimentos: booking.procedimentos,
      status: booking.status || 'Agendado',
      observacao: booking.observacao,
      valorTotal: booking.valorTotal,
      paymentMethod: booking.paymentMethod || '',
      paymentStatus: booking.paymentStatus || '',
      paymentDate: booking.paymentDate || booking.datetimeAtendimento
    });
    setShowEditModal(true);
  };

  const handleUpdateBooking = async (status) => {
    setIsLoading(true);
    try {
      console.log("Updating booking:", selectedBooking);
      const updates = {
        atendimento_id: selectedBooking.atendimento_id,
        ...editFormData,
        status: status || editFormData.status
      };

      console.log("Updated booking:", updates);
      
      await updateBooking(updates);
      setShowEditModal(false);
      handleSearch(); // Refresh the list
    } catch (error) {
      console.error('Error updating booking:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderBookingsList = () => {
    // Find professional name by ID
    const getProfessionalName = (professionalId) => {
      const professional = professionals.find(p => p.id === professionalId);
      return professional ? professional.name : professionalId;
    };

    return (
      <div className="bookings-list">
        <h3>Agendamentos</h3>
        {bookings.length === 0 ? (
          <p>Nenhum agendamento encontrado.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Profissional</th>
                <th>Data</th>
                <th>Horário</th>
                <th>Procedimentos</th>
                <th>Status</th>
                <th>Calendário</th>
              </tr>
            </thead>
            <tbody>
              {bookings
                .filter(booking => booking.status !== 'Cancelado')
                .map((booking, index) => {
                const timeOnly = booking.datetimeAtendimento ? 
                  booking.datetimeAtendimento.split('T')[1].slice(0, 5) : 
                  'N/A';

                return (
                  <tr 
                    key={index} 
                    onClick={() => handleEditBooking(booking)}
                    style={{ 
                      cursor: 'pointer',
                      backgroundColor: booking.status === 'Realizado' ? '#2e7d32' : 'inherit',
                      color: booking.status === 'Realizado' ? '#ffffff' : 'inherit'
                    }}
                  >
                    <td>{booking.paciente}</td>
                    <td>{getProfessionalName(booking.profissional)}</td>
                    <td>{booking.dataAtendimento}</td>
                    <td>{timeOnly}</td>
                    <td>
                      {booking.procedimentos.map((proc, i) => (
                        <div key={i}>{proc.nome}</div>
                      ))}
                    </td>
                    <td>{booking.status || 'Agendado'}</td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      booking.googleEventUrl && window.open(booking.googleEventUrl, '_blank');
                    }}>
                      {booking.googleEventUrl && (
                        <FaCalendarAlt 
                          style={{ 
                            cursor: 'pointer',
                            color: '#4a90e2'
                          }} 
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const MissingFieldsModal = () => {
    if (!showMissingFieldsModal) return null;

    return (
      <div className="modal-overlay" onClick={() => setShowMissingFieldsModal(false)}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button className="close-button" onClick={() => setShowMissingFieldsModal(false)}>×</button>
          <h3>Campos Obrigatórios</h3>
          <p>Por favor, preencha os campos a seguir para poder agendar:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="booking-container">
      <div className="booking-tabs">
        {!isCaixa && (
          <button 
            className={`tab-button ${activeTab === 'create' ? 'active' : ''}`}
            onClick={() => setActiveTab('create')}
          >
            Criar Agendamento
          </button>
        )}
        <button 
          className={`tab-button ${activeTab === 'view' ? 'active' : ''}`}
          onClick={() => setActiveTab('view')}
        >
          Ver Agendamentos
        </button>
      </div>

      {activeTab === 'create' ? (
        <>
          <h2>Fazer um agendamento</h2>
          
          <div className="booking-grid">
            <div className="booking-section">
              <h3>Escolha o profissional <span style={{color: 'red'}}>*</span></h3>
              <div className="professional-grid">
                {professionals.map(prof => (
                  <div 
                    key={prof.id} 
                    className={`professional-card ${selectedProfessional?.id === prof.id ? 'selected' : ''}`}
                    onClick={() => setSelectedProfessional(prof)}
                  >
                    <h4>{prof.name}</h4>
                    <p>{prof.specialty}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="booking-section">
              <h3>Escolha o paciente <span style={{color: 'red'}}>*</span></h3>
              <PacientSearchSelect 
                ref={patientSearchRef}
                onPatientSelect={handlePatientSelect} 
              />
            </div>

            <div className="booking-section">
              <h3>Escolha um ou mais procedimentos <span style={{color: 'red'}}>*</span></h3>
              <div className="procedure-search">
                <input
                  type="text"
                  placeholder="Buscar procedimentos..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="procedure-search-input"
                />
              </div>
              
              {!showSubProcedures ? (
                <div className="procedure-list">
                  {filteredProcedures.map((proc) => (
                    <div 
                      key={proc.name}
                      className="procedure-item"
                      onClick={() => handleProcedureSelect(proc)}
                    >
                      {proc.name}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="subprocedure-list">
                  <div className="subprocedure-header">
                    <button onClick={() => setShowSubProcedures(false)}>Voltar</button>
                    <h4>{currentProcedure.name}</h4>
                  </div>
                  {Object.entries(currentProcedure.subcategorias).map(([key, value]) => (
                    <div 
                      key={key}
                      className="subprocedure-item"
                      onClick={() => handleSubProcedureSelect(key, value)}
                    >
                      {key} - R$ {value.valor}
                    </div>
                  ))}
                </div>
              )}

              <div className="selected-procedures">
                <h4>Procedimentos Selecionados:</h4>
                {renderSelectedProcedures()}
              </div>
            </div>

            <div className="booking-section">
              <h3>Escolha a data e horário <span style={{color: 'red'}}>*</span></h3>
              <div className="date-time-container">
                <div 
                  className="date-input-container"
                  onClick={() => document.querySelector('.date-input').showPicker()}
                >
                  <input 
                    type="date" 
                    className="date-input"
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
                <div className="time-grid">
                  <div className="time-selection-container">
                    <div className="time-column">
                      <h4>Hora</h4>
                      <div className="time-scroll">
                        {[...Array(15)].map((_, i) => (
                          <div 
                            key={i} 
                            className={`time-item ${selectedHour === (i + 8).toString() ? 'selected' : ''}`}
                            onClick={() => setSelectedHour((i + 8).toString())}
                          >
                            {(i + 8).toString().padStart(2, '0')}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="time-column">
                      <h4>Minuto</h4>
                      <div className="time-scroll">
                        {[...Array(4)].map((_, i) => (
                          <div 
                            key={i} 
                            className={`time-item ${selectedMinute === (i * 15).toString() ? 'selected' : ''}`}
                            onClick={() => setSelectedMinute((i * 15).toString())}
                          >
                            {(i * 15).toString().padStart(2, '0')}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="duration-input">
                  <label>Duração em minutos:</label>
                  <input 
                    type="number" 
                    className="date-input"
                    value={selectedDuration}
                    onChange={(e) => setSelectedDuration(e.target.value)}
                    placeholder="Tempo de duração"
                    min="15"
                    step="15"
                  />
                </div>
              </div>
            </div>
            {/* <div className="required-fields">
              <p><span style={{color: 'red'}}>*</span> Campos obrigatórios</p>
            </div> */}

            <div className="booking-section">
              <h3>Observação</h3>
              <textarea 
                className="date-input"
                value={generalObservation}
                onChange={(e) => setGeneralObservation(e.target.value)}
                placeholder="Observações gerais sobre o agendamento"
                rows="4"
              />
            </div>
          </div>

          <button 
            className="book-button"
            onClick={handleBooking}
          >
            {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : 'Confirmar Agendamento'}
          </button>

          {successMessage && (
            <div className={`success-message ${successMessage.includes('erro') ? 'error' : 'success'}`}>
              {successMessage}
            </div>
          )}
        </>
      ) : (
        <div className="bookings-search-container">
          <div className="google-calendar-banner dark">
            <p>Também é possível ver os agendamentos no Calendário Google</p>
            <a href="https://calendar.google.com/calendar/u/0?cid=OWU0NmQ5NmEwMzZmMmE1MzU5NTc1MzUwNmI5ZmNhMWY2YjU5NzNkNDZmODVjZGVjZDdmMThkM2E1NTA4NGFjZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t" target="_blank" rel="noopener noreferrer" className="google-calendar-button">
              Abrir Google Calendar
            </a>
          </div>
          <div className="bookings-search-filters">
            <div className="search-professional">
              <label>Profissional</label>
              <select 
                value={searchProfessional?.name || ''} 
                onChange={(e) => {
                  const selected = professionals.find(p => p.name === e.target.value);
                  setSearchProfessional(selected || null);
                }}
              >
                <option value="">Todos Profissionais</option>
                {professionals.map(prof => (
                  <option key={prof.name} value={prof.name}>
                    {prof.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="search-patient">
              <label>Paciente</label>
              <PacientSearchSelect 
                onPatientSelect={(patient) => setSearchPatient(patient)}
                placeholder="Buscar Paciente"
              />
            </div>

            <div className="search-date">
              <label>Data</label>
              <div 
                className="date-input-container"
                onClick={() => document.querySelector('.search-date input[type="date"]').showPicker()}
              >
                <input 
                  type="date" 
                  value={searchDate} 
                  onChange={(e) => setSearchDate(e.target.value)}
                  className="date-input"
                />
              </div>
            </div>

            <button 
              className="search-button" 
              onClick={handleSearch}
            >
              Buscar
            </button>
          </div>
          {renderBookingsList()}
        </div>
      )}

      <MissingFieldsModal />
      
      <EditBookingModal 
        showModal={showEditModal}
        formData={editFormData}
        onClose={() => setShowEditModal(false)}
        onUpdate={setEditFormData}
        onUpdateStatus={handleUpdateBooking}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Booking; 