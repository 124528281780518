import axios from 'axios';

const BASE_URL = 'https://upqsv1xash.execute-api.us-east-1.amazonaws.com/prod/';

export const fetchProcedimentos = async () => {
  const cachedProcedures = localStorage.getItem('cached_procedimentos');
  const cachedTimestamp = localStorage.getItem('procedimentos_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProcedures && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProcedures);
    }
  }

  try {
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        config_id: '1',
      }),
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_procedimentos', JSON.stringify(data));
    localStorage.setItem('procedimentos_cache_timestamp', Date.now().toString());
    
    // console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Procedimentos:', error);
    // Return cached data if available in case of network error
    const cachedProcedures = localStorage.getItem('cached_procedimentos');
    return cachedProcedures ? JSON.parse(cachedProcedures) : [];
  }
};

export const fetchProfessionals = async () => {
  const cachedProfessionals = localStorage.getItem('cached_professionals');
  const cachedTimestamp = localStorage.getItem('professionals_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProfessionals && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProfessionals);
    }
  }

  try {
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        config_id: '2',
      }),
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_professionals', JSON.stringify(data));
    localStorage.setItem('professionals_cache_timestamp', Date.now().toString());
    
    // console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Professionals:', error);
    // Return cached data if available in case of network error
    const cachedProfessionals = localStorage.getItem('cached_professionals');
    return cachedProfessionals ? JSON.parse(cachedProfessionals) : [];
  }
};

export const fetchPatients = async (searchTerm = '') => {
  try {
    const response = await fetch(`${BASE_URL}get-pacientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchTerm ? { search: searchTerm } : {}),
    });
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Patients:', error);
    return [];
  }
};

export const createPatient = async (patient) => {
  try {
    const response = await fetch(`${BASE_URL}create-paciente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating patient:', error);
    throw error;
  }
};

export const createItem = async (item) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};

export const deleteItem = async (eventId) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item: eventId }),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const fetchEvents = async (queryParams = {}) => {
  try {
    const url = new URL(`${BASE_URL}get-history`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Events:', error);
    return [];
  }
};

export const fetchAnamnesis = async (patientId = null) => {
  try {
    const response = await fetch(`${BASE_URL}get-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patientId ? { patient_id: patientId } : {}),
    });

    const data = await response.json();
    
    return (data.anamneses || []).map(item => ({
      id: item.anamnese_id,
      date: item.date || 'N/A',
      patient: item.patient_name || 'N/A',
      patientId: item.patient_id || 'N/A',
      content: item.anamnesis_text
    }));
  } catch (error) {
    console.error('Error fetching Anamnesis:', error);
    return [];
  }
};

export const sendToReception = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}send-to-reception`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error sending to reception:', error);
    throw error;
  }
};

export const createBudget = async (budgetData) => {
  try {
    const response = await fetch(`${BASE_URL}create-budget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(budgetData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating budget:', error);
    throw error;
  }
};

export const transcribeAudio = async (audioFile, apiKey) => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', 'whisper-large-v3');
    formData.append('language', 'pt');
    formData.append('prompt', 'Transcrição de áudio em português brasileiro para uma clínica de dermatologia');

    const response = await axios.post(
      'https://api.groq.com/openai/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data.text;
  } catch (error) {
    console.error('Transcription error:', error.response || error);
    throw error;
  }
};

export const generateAnamnesis = async (transcriptionText, selectedPatient) => {
  try {
    const response = await fetch(`${BASE_URL}create-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: transcriptionText,
        patient: selectedPatient || null
      })
    });

    const data = await response.json();
    
    return data.anamnesis || '';

  } catch (error) {
    console.error('Anamnesis generation error:', error.response?.data || error);
    throw error;
  }
};

export const resetConfigCache = () => {
  // Remove cached procedures
  localStorage.removeItem('cached_procedimentos');
  localStorage.removeItem('procedimentos_cache_timestamp');
  
  // Remove cached professionals
  localStorage.removeItem('cached_professionals');
  localStorage.removeItem('professionals_cache_timestamp');
  
  console.log('Configuration cache has been reset');
};

export const createBooking = async (bookingData) => {
  try {
    const response = await fetch(`${BASE_URL}create-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const fetchBookings = async (queryParams = {}) => {
  try {
    const response = await fetch(`${BASE_URL}get-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...queryParams,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate
      })
    });
    

    const data = await response.json();
    return data.bookings || [];
  } catch (error) {
    console.error('Error fetching Bookings:', error);
    return [];
  }
};

export const updateBooking = async (updates) => {
  try {
    const response = await fetch(`${BASE_URL}update-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });

    if (!response.ok) {
      throw new Error('Problema ao atualizar o agendamento.');
    }


    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating booking:', error);
    throw error;
  }
};



export const getStock = async (product_id) => {
    try {
        const url = new URL(`${BASE_URL}get-stock`);
        if (product_id) {
            url.searchParams.append('product_id', product_id);
        }

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Problema ao buscar o item de estoque.');
        }
        return await response.json();

    } catch (error) {
        console.error('Error fetching stock item:', error);
        throw error;
    }
};

export const updateStock = async (stockData) => {
    try {
        const response = await fetch(`${BASE_URL}create-stock-change`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(stockData)
        });
        
        if (!response.ok) {
            throw new Error('Problema ao atualizar o estoque.');
        }
        

        return await response.json();
    } catch (error) {
        console.error('Error updating stock:', error);
        throw error;
    }
};


export const fetchStock = async (paginationToken = null) => {
  try {
    const url = new URL(`${BASE_URL}get-stock`);
    if (paginationToken) {
      url.searchParams.append('pagination_token', paginationToken);
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return {
      items: data.items || [],
      count: data.count || 0,
      paginationToken: data.pagination_token
    };
  } catch (error) {
    console.error('Error fetching stock:', error);
    throw error;
  }
};

export const getStockMovements = async (paginationToken = null) => {
    try {
        const url = new URL(`${BASE_URL}get-stock-change`);
        if (paginationToken) {
            url.searchParams.append('pagination_token', paginationToken);
        }

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Problema ao buscar as alteracoes de estoque.');
        }
        const data = await response.json();

        return {
          items: data.items || [],
          count: data.count || 0,
          paginationToken: data.pagination_token
        };
      } catch (error) {
        console.error('Error fetching stock movements:', error);
        throw error;
    }
}

export const fetchStockRequisitions = async (paginationToken = null, status = null) => {
  try {
    const url = new URL(`${BASE_URL}get-stock-requisition`);
    if (paginationToken) {
      url.searchParams.append('pagination_token', paginationToken);
    }
    if (status) {
      url.searchParams.append('status', status);
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return {
      items: data.items || [],
      count: data.count || 0,
      paginationToken: data.pagination_token
    };
  } catch (error) {
    console.error('Error fetching stock requisitions:', error);
    throw error;
  }
};

export const createStockRequisition = async (requisitionData) => {
  try {
    const response = await fetch(`${BASE_URL}create-stock-requisition`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requisitionData)
    });
    
    if (!response.ok) {
      throw new Error('Falha ao criar a requisicao de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating stock requisition:', error);
    throw error;
  }
};

export const createStockChanges = async (changes) => {
  try {
    const response = await fetch(`${BASE_URL}create-stock-change`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao processar as alteracoes de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating stock changes:', error);
    throw error;
  }
};

export const createProduct = async (productData) => {
  try {
    const response = await fetch(`${BASE_URL}create-product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(productData)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar o produto.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const response = await fetch(`${BASE_URL}create-invoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(invoiceData)
      body: JSON.stringify({
        nf_number: invoiceData.nf_number,
        date: invoiceData.date,
        expiration_date: invoiceData.expiration_date,
        supplier: invoiceData.supplier,
        supplier_id: invoiceData.supplier_id,
        notes: invoiceData.notes,
        taxes: parseFloat(invoiceData.taxes) || 0,
        shipping: parseFloat(invoiceData.shipping) || 0,
        items: invoiceData.items.map(item => ({
          product_id: item.product_id,
          description: item.description,
          quantity: parseInt(item.quantity),
          price: parseFloat(item.price)
        })),
        payments: invoiceData.payments.map(payment => ({
          date: payment.date,
          amount: parseFloat(payment.amount),
          file: payment.file,
          file_name: payment.file_name,
          install_payment: payment.install_payment,
          last_payment: payment.last_payment
        })),
        file: invoiceData.file,
        file_name: invoiceData.file_name

      })

    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar a nota fiscal.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating invoice:', error);
    throw error;
  }
};

export const fetchInvoices = async (paginationToken = null, filters = {}) => {
    try {
        const response = await fetch(`${BASE_URL}get-invoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paginationToken,
                ...filters,
                start_date: filters.startDate,
                end_date: filters.endDate
            }),
        });

        if (!response.ok) {
            throw new Error('HTTP error ' + response.status);
        }

        const data = await response.json();
        return {
            items: data.invoices || [],
            paginationToken: data.paginationToken
        };
    } catch (error) {
        console.error('Error fetching invoices:', error);
        throw error;
    }
};

export const createPayment = async (paymentData) => {
  try {
    const response = await fetch(`${BASE_URL}create-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...paymentData,
        status: 'sent'
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar o pagamento.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
};

export const fetchPayments = async (filters = {}) => {
  try {
    const url = new URL(`${BASE_URL}get-payments`);
    if (filters.status) url.searchParams.append('status', filters.status);
    if (filters.start_date) url.searchParams.append('start_date', filters.start_date);
    if (filters.end_date) url.searchParams.append('end_date', filters.end_date);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error('Error fetching payments:', error);
    throw error;
  } 
};

export const updateStockRequisition = async (requisitionId, timestamp, updates = {}, isDelete = false) => {
  try {
    console.log("updating requisition", requisitionId, timestamp, updates, isDelete);
    const response = await fetch(`${BASE_URL}update-requisition`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requisition_id: requisitionId,
        timestamp: timestamp,
        ...(isDelete ? { action: 'delete' } : updates)
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Problema ao atualizar a requisicao de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating stock requisition:', error);
    throw error;
  }
};

export const updatePayment = async (paymentId, updates = {}, isDelete = false) => {
  try {
    const response = await fetch(`${BASE_URL}update-payment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payment_id: paymentId,
        ...(isDelete ? { action: 'delete' } : updates)
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Problema ao atualizar o pagamento.');
    }
    

    return await response.json();
  } catch (error) {
    console.error('Error updating payment:', error);
    throw error;
  }
};

export const getFileFromS3 = async (fileKey, download = false) => {
  try {
    const response = await fetch(`${BASE_URL}get-file-from-s3`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ file_key: fileKey })
    });

    if (!response.ok) {
      throw new Error('Problema ao baixar o arquivo.');
    }

    const data = await response.json();
    if (download) {
      const a = document.createElement('a');
      a.href = data.url;
      a.target = '_blank';
      a.download = fileKey.split('/').pop();
      a.rel = 'noopener noreferrer';
      a.click();
    }
    return data.url;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};

export const createSupplier = async (supplierData) => {
  try {
    const response = await fetch(`${BASE_URL}create-supplier`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(supplierData),
    });
    
    if (!response.ok) {
      throw new Error('Failed to create supplier');
    }
    
    return response.json();
  } catch (error) {
    console.error('Error creating supplier:', error);
    throw error;
  }
};

export const fetchSuppliers = async (searchTerm) => {
  try {
    const response = await fetch(`${BASE_URL}get-supplier`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ search: searchTerm })
    });
    

    if (!response.ok) {
      throw new Error('Failed to fetch suppliers');
    }
    
    const data = await response.json();
    return data.suppliers;
  } catch (error) {
    console.error('Error fetching suppliers:', error);
    throw error;
  }
};

