import React, { useEffect, useCallback } from 'react';
import './Booking.css';
import Spinner from '../Spinner/Spinner';
import { FaFileUpload } from 'react-icons/fa';

function EditBookingModal({ 
  showModal, 
  formData, 
  onClose, 
  onUpdate, 
  onUpdateStatus,
  isLoading 
}) {
  const handleEscapeKey = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal, handleEscapeKey]);

  if (!showModal || !formData) return null;

  return (
    <div 
      className="modal-overlay"
      onClick={onClose}
    >
      <div 
        className="modal-content dark"
        onClick={e => e.stopPropagation()}
      >
        <h2>Editar Agendamento</h2>
        
        {isLoading ? (
          <div className="loading-container">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label>Data e Hora</label>
              <input
                type="datetime-local"
                value={formData.dataAtendimento.slice(0, 16)}
                onChange={(e) => onUpdate({
                  ...formData,
                  dataAtendimento: e.target.value + ':00Z'
                })}
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Duração (minutos)</label>
              <input
                type="number"
                value={formData.duration}
                onChange={(e) => onUpdate({
                  ...formData,
                  duration: e.target.value
                })}
                step="15"
                min="15"
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Valor Total (R$)</label>
              <input
                type="number"
                value={formData.valorTotal !== null && formData.valorTotal !== undefined ? formData.valorTotal : ''}
                onChange={(e) => onUpdate({
                  ...formData,
                  valorTotal: e.target.value
                })}
                step="0.01"
                min="0"
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Modo de Pagamento</label>
              <select
                value={formData.paymentMethod || ''}
                onChange={(e) => onUpdate({
                  ...formData,
                  paymentMethod: e.target.value
                })}
                className="dark-input"
                disabled={isLoading}
              >
                <option value="">Selecione o modo de pagamento</option>
                <option value="Pix">Pix</option>
                <option value="Crédito">Crédito</option>
                <option value="Débito">Débito</option>
                <option value="Dinheiro">Dinheiro</option>
              </select>
            </div>

            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="patientArrived" className="checkbox-label">Paciente Chegou?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="patientArrived"
                    checked={formData.status === 'Chegou'}
                    onChange={(e) => onUpdate({
                      ...formData,
                      status: e.target.checked ? 'Chegou' : 'Agendado'
                    })}
                    disabled={isLoading || formData.status === 'Realizado'}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="appointmentCompleted" className="checkbox-label">Atendimento Finalizado?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="appointmentCompleted"
                    checked={formData.status === 'Realizado'}
                    onChange={(e) => onUpdate({
                      ...formData,
                      status: e.target.checked ? 'Realizado' : formData.status === 'Chegou' ? 'Chegou' : 'Agendado'
                    })}
                    disabled={isLoading}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="paymentReceived" className="checkbox-label">Pagamento realizado?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="paymentReceived"
                    checked={formData.paymentStatus === 'Realizado'}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked && !formData.paymentMethod) {
                        alert('Por favor, selecione o método de pagamento');
                        e.preventDefault();
                        return;
                      }
                      onUpdate({
                        ...formData,
                        paymentStatus: isChecked ? 'Realizado' : '',
                        status: isChecked ? 'Realizado' : formData.status
                      });
                    }}
                    disabled={isLoading}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>

            {formData.paymentStatus === 'Realizado' && (
              <>
                <div className="form-group">
                  <label>Data do Pagamento</label>
                  <input
                    type="datetime-local"
                    value={(formData.paymentDate || formData.dataAtendimento).slice(0, 16)}
                    onChange={(e) => onUpdate({
                      ...formData,
                      paymentDate: e.target.value + ':00Z'
                    })}
                    className="dark-input"
                    disabled={isLoading}
                  />
                </div>

                <div className="form-group">
                  <label>Comprovante de Pagamento:</label>
                  <div className="file-upload-wrapper">
                    <input
                      type="file"
                      id="payment-proof"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64 = reader.result.split(',')[1];
                            onUpdate({
                              ...formData,
                              file: base64,
                              file_name: file.name
                            });
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                      accept=".pdf, .jpg, .jpeg, .png"
                      style={{ display: 'none' }}
                      disabled={isLoading}
                    />
                    <label htmlFor="payment-proof" className="file-upload-label">
                      <FaFileUpload /> {formData.file_name || 'Anexar comprovante'}
                    </label>
                  </div>
                </div>
              </>
            )}

            <div className="form-group">
              <label>Observação</label>
              <textarea
                value={formData.observacao || ''}
                onChange={(e) => onUpdate({
                  ...formData,
                  observacao: e.target.value
                })}
                rows="4"
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="modal-actions">
              <div className="left-buttons">
                <button 
                  className="action-button danger"
                  onClick={() => onUpdateStatus('Cancelado')}
                  disabled={isLoading}
                >
                  Cancelar agendamento
                </button>
              </div>
              <div className="right-buttons">
                <button 
                  className="action-button"
                  onClick={() => onUpdateStatus()}
                  disabled={isLoading}
                >
                  Salvar Alterações
                </button>
                <button 
                  className="action-button secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Fechar
                </button>
              </div>
            </div>

            <style>
              {`
                .checkbox-container {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                }

                .checkbox-label {
                  font-weight: 500;
                  color: #333;
                }

                .custom-checkbox {
                  position: relative;
                  display: inline-block;
                  cursor: pointer;
                }

                .custom-checkbox input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 24px;
                  width: 24px;
                  top: 0;
                  left: 0;
                  margin: 0;
                  z-index: 1;
                }

                .checkmark {
                  position: relative;
                  display: inline-block;
                  height: 24px;
                  width: 24px;
                  background-color: #2c2c2c;
                  border: 2px solid #4a4a4a;
                  border-radius: 4px;
                  transition: all 0.2s ease;
                  pointer-events: none;
                }

                .custom-checkbox:hover .checkmark {
                  background-color: #3c3c3c;
                }

                .custom-checkbox input:checked ~ .checkmark {
                  background-color: #4a90e2;
                  border-color: #4a90e2;
                }

                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 8px;
                  top: 4px;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }

                .custom-checkbox input:checked ~ .checkmark:after {
                  display: block;
                }

                .custom-checkbox input:disabled ~ .checkmark {
                  opacity: 0.5;
                  cursor: not-allowed;
                }

                .modal-actions {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 20px;
                }

                .right-buttons {
                  display: flex;
                  gap: 10px;
                }

                .file-upload-wrapper {
                  margin-top: 8px;
                }

                .file-upload-label {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 8px 16px;
                  background-color: #2c2c2c;
                  border: 2px solid #4a4a4a;
                  border-radius: 4px;
                  cursor: pointer;
                  transition: all 0.2s ease;
                }

                .file-upload-label:hover {
                  background-color: #3c3c3c;
                }

                .file-upload-label svg {
                  font-size: 1.2em;
                }
              `}
            </style>
          </>
        )}
      </div>
    </div>
  );
}

export default EditBookingModal; 