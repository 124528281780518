import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="app-header">
      <div className="header-content">
        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="Nexus Logo" className="header-logo" />
        <h1 className="header-title">Nexus</h1>
      </div>
    </header>
  );
};

export default Header; 