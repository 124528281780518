import React, { useState } from 'react';
import { createSupplier } from '../api';
import './Estoque.css';

function SupplierModal({ show, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    cnpj: '',
    address: '',
    description: '',
    contact_person: '',
    tax_id: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      await createSupplier(formData);
      onClose();
      setFormData({
        name: '',
        email: '',
        phone: '',
        cnpj: '',
        address: '',
        description: '',
        contact_person: '',
        tax_id: ''
      });
    } catch (error) {
      console.error('Error creating supplier:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Novo Fornecedor</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nome*</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email*</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Telefone*</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>CNPJ*</label>
            <input
              type="text"
              name="cnpj"
              value={formData.cnpj}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Endereço</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Descrição</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Pessoa de Contato</label>
            <input
              type="text"
              name="contact_person"
              value={formData.contact_person}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Inscrição Estadual</label>
            <input
              type="text"
              name="tax_id"
              value={formData.tax_id}
              onChange={handleChange}
            />
          </div>
          <div className="modal-footer">
            <button 
              type="submit" 
              className="success" 
              disabled={isProcessing}
            >
              {isProcessing ? 'Processando...' : 'Salvar'}
            </button>
            <button 
              type="button" 
              onClick={onClose}
              className="dark-button"
              disabled={isProcessing}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SupplierModal; 