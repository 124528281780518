import React, { useState, useCallback, useMemo, useImperativeHandle, forwardRef } from 'react';
import { fetchSuppliers } from '../api';
import debounce from 'lodash/debounce';
import './SupplierSearchSelect.css';

const SupplierSearchSelect = forwardRef(({ onSupplierSelect }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  useImperativeHandle(ref, () => ({
    resetSearch: () => {
      setSearchTerm('');
      setSuppliers([]);
      setSelectedSupplier(null);
      setHasSearched(false);
    }
  }));

  const debouncedSearch = useMemo(
    () => debounce(async (term) => {
      if (!term || term.length < 3) {
        setSuppliers([]);
        setHasSearched(false);
        return;
      }

      setIsLoading(true);
      try {
        const results = await fetchSuppliers(term);
        setSuppliers(results);
        setHasSearched(true);
      } catch (error) {
        console.error('Supplier search error:', error);
        setSuppliers([]);
        setHasSearched(true);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = useCallback((e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    if (selectedSupplier) {
      setSelectedSupplier(null);
    }
    
    debouncedSearch(term);
  }, [debouncedSearch, selectedSupplier]);

  const handleSupplierSelection = useCallback((supplier) => {
    onSupplierSelect(supplier);
    setSelectedSupplier(supplier);
    setSearchTerm('');
    setSuppliers([]);
  }, [onSupplierSelect]);

  return (
    <div className="supplier-search-container">
      <div className="search-input-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={selectedSupplier ? selectedSupplier.name : "Digite o nome do fornecedor"}
          className="search-input"
        />
      </div>

      {isLoading && <div className="loading-indicator">Carregando...</div>}

      {suppliers.length > 0 && (
        <div className="supplier-dropdown">
          {suppliers.map((supplier) => (
            <div
              key={supplier.supplier_id}
              onClick={() => handleSupplierSelection(supplier)}
              className="supplier-option"
            >
              <span className="supplier-name">{supplier.name}</span>
              {supplier.cnpj && <span className="supplier-cnpj">CNPJ: {supplier.cnpj}</span>}
            </div>
          ))}
        </div>
      )}

      {hasSearched && !isLoading && suppliers.length === 0 && !selectedSupplier && (
        <div className="no-results">
          <div>Nenhum fornecedor encontrado</div>
        </div>
      )}
    </div>
  );
});

export default SupplierSearchSelect; 