import React, { useEffect } from 'react';
import { getFileFromS3, updatePayment } from '../api';
import { USER_GROUPS } from '../../config/permissions';
import { useAuth } from '../../hooks/useAuth';
import './Financeiro.css';

function PaymentInfoModal({ show, onClose, payment, onUpdate }) {
    const { user } = useAuth();
    const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
    const isAdmin = userGroups.includes(USER_GROUPS.ADMIN);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    const handleMarkAsPaid = async () => {
        try {
            await updatePayment(payment.payment_id, { status: 'paid' });
            if (onUpdate) onUpdate();
            onClose();
        } catch (error) {
            console.error('Error marking payment as paid:', error);
            alert('Erro ao marcar pagamento como pago');
        }
    };

    if (!show) return null;

    const handleBackgroundClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    const handleDownload = async () => {
        try {
            await getFileFromS3(payment.file_key);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Erro ao baixar o arquivo');
        }
    };

    return (
        <div className="modal-overlay" onClick={handleBackgroundClick}>
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Detalhes do Pagamento</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="payment-details">
                        <div className="details-section">
                            <div className="details-row">
                                <div className="details-group">
                                    <label>Código de referência</label>
                                    <span>{payment.payment_id || '-'}</span>
                                </div>
                                {payment.file_key && (
                                    <div className="details-group">
                                        <button 
                                            className="primary-button" 
                                            onClick={handleDownload}
                                        >
                                            Baixar comprovante
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="details-row">
                                <div className="details-group">
                                    <label>Data de Criação</label>
                                    <span>{payment.created_at ? new Date(payment.created_at).toLocaleDateString() : '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Data de Vencimento</label>
                                    <span>{payment.expiration_date ? new Date(payment.expiration_date).toLocaleDateString() : '-'}</span>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group">
                                    <label>Fornecedor</label>
                                    <span>{payment.supplier || '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Método de Pagamento</label>
                                    <span>{payment.payment_method || '-'}</span>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group">
                                    <label>Status</label>
                                    <span>{payment.status || '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Valor Total</label>
                                    <span className="total-value">R$ {payment.amount ? parseFloat(payment.amount).toFixed(2) : '0.00'}</span>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Descrição</label>
                                    <span>{payment.description || '-'}</span>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Observações</label>
                                    <span>{payment.notes || '-'}</span>
                                </div>
                            </div>

                            {isAdmin && payment.status !== 'paid' && (
                                <div className="details-row">
                                    <div className="details-group full-width">
                                        <button 
                                            className="primary-button success" 
                                            onClick={handleMarkAsPaid}
                                        >
                                            Marcar como pago
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentInfoModal; 