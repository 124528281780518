import React, { useState, useEffect } from 'react';
import { createProduct } from '../api';
import './Estoque.css';

function ProductModal({ show, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        description: '',
        ideal: 0,
        min: 0,
        price: 0,
        qty: 0
    });
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setProcessing(true);
            setError('');
            
            const result = await createProduct(formData);
            onSubmit(result.product);
            onClose();
        } catch (err) {
            setError(err.message || 'Error creating product');
        } finally {
            setProcessing(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <h2>Novo Produto</h2>
                <form onSubmit={handleSubmit}>
                    {error && <div className="error-message">{error}</div>}
                    
                    <div className="form-group">
                        <label>Descrição:</label>
                        <input
                            type="text"
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Quantidade Ideal:</label>
                        <input
                            type="number"
                            min="0"
                            value={formData.ideal}
                            onChange={(e) => setFormData(prev => ({ ...prev, ideal: parseInt(e.target.value) }))}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Quantidade Mínima:</label>
                        <input
                            type="number"
                            min="0"
                            value={formData.min}
                            onChange={(e) => setFormData(prev => ({ ...prev, min: parseInt(e.target.value) }))}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Preço:</label>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            value={formData.price}
                            onChange={(e) => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Quantidade Inicial:</label>
                        <input
                            type="number"
                            min="0"
                            value={formData.qty}
                            onChange={(e) => setFormData(prev => ({ ...prev, qty: parseInt(e.target.value) }))}
                            required
                        />
                    </div>

                    <div className="modal-actions">
                        <button 
                            type="submit" 
                            className="success"
                            disabled={processing}
                        >
                            {processing ? 'Processando...' : 'Criar Produto'}
                        </button>
                        <button type="button" onClick={onClose}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProductModal; 