import React from 'react';
import './Reports.css';

function Reports() {
    return (
        <div className="reports-container">
            <h2>Relatórios</h2>
            <div className="reports-content">
                {/* Reports content will be added here */}
                <p>Página de relatórios em desenvolvimento</p>
            </div>
        </div>
    );
}

export default Reports; 